/*eslint-disable*/

import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import OrderFormTemplate from "./OrderFormTemplate";
import { useGlobalState } from "components/globalVariable";
import { useDispatch, useSelector } from "react-redux";
import {
  setTradingSymbol,
  setShowOrderForm,
} from "components/OptionChain/OptionChainSlice";
import {
  setType,
  setSecurities,
  setIsSecuritySelect,
  setLots,
  setLotSize,
  setUpdateData,
  setExchangeList,
  setProduct,
  setExchange,
} from "./orderFormSlice";
import { saveOrderDetails } from "components/PositionsGrid/container/PositionActionSlice";
import { useRealtimeZenSecurities2 } from "components/getAllSourcesSecurities";
import useFormAction from "./FormAction";
import { saveExchange } from "reducers/FilterSlice";
import {
  selectBroker,
  selectAccount,
  selectStrategy,
} from "reducers/BrokerSlice.js";

let OrderType = [
  "MARKET",
  "LIMIT",
  "STANDALONE_SL_LIMIT",
  "STANDALONE_SL_MARKET",
  "LIMIT_ORDER_WITH_SL_LIMIT",
  "MARKET_ORDER_WITH_SL_LIMIT",
  "LIMIT_ORDER_WITH_SL_MARKET",
  "MARKET_ORDER_WITH_SL_MARKET",
  "TRAILING_STOP_LOSS_MARKET_ORDER",
  "TRAILING_STOP_LOSS_LIMIT_ORDER",
];
let orderType = OrderType;
const Prices = ["triggerPrice", "limitPrice", "stopLossLimitPrice"];
const TriggerPoints = [
  "activationPoints",
  "trailingTriggerPoints",
  "trailingLimitPoints",
];

function OrderForm({ setFormOpen, trade, targetNode, hideForm }) {
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const orderValue = useSelector((state) => state.orderForm.orderValue);
  const [zen, setZen] = useState(targetNode);
  const dispatch = useDispatch();
  const autoSquareTradingSymbol = useSelector(
    (state) => state.positionActions.tradingSymbol
  );
  const optionChainTradingSymbol = useSelector(
    (state) => state.optionChainTemplate.tradingSymbol
  );
  const orderExchange = useSelector((state) => state.orderForm.exchange);
  const postionTableRowData = useSelector(
    (state) => state.positionActions.orderDetails
  );
  const zenSecuritiesData1 = useRealtimeZenSecurities2();
  const type = useSelector((state) => state.orderForm.type);
  const autocompleteValue = useSelector(
    (state) => state.orderForm.autocompleteValue
  );
  const isLotsFocus = useSelector((state) => state.orderForm.isLotsFocus);
  const lotSize = useSelector((state) => state.orderForm.lotSize);
  const lots = useSelector((state) => state.orderForm.lots);
  const isQtyFocus = useSelector((state) => state.orderForm.isQtyFocus);
  const updateData = useSelector((state) => state.orderForm.updateData);
  const filter = useSelector((state) => state.filter);
  const isUpdateForm = useSelector((state) => state.orderForm.isUpdateForm);
  const selectedAccount = useSelector((state) => state.broker.selectedAccount);
  const selectedStrategy = useSelector(
    (state) => state.broker.selectedStrategy
  );
  const selectedBroker = useSelector((state) => state.broker.selectedBroker);
  const brokers = useSelector((state) => state.broker.brokers);
  const accounts = useSelector((state) => state.broker.accounts);
  const strategies = useSelector((state) => state.broker.strategies);
  const graphTradingSymbol = useSelector(
    (state) => state.mainTemplate.graphTradingSymbol
  );
  const [formData, setFormData] = useState({
    clientId: clientId,
    accountId: postionTableRowData?.accountId
      ? postionTableRowData?.accountId
      : updateData !== undefined
      ? updateData?.positionCompKey?.accountId
      : selectedAccount,
    strategyId: postionTableRowData?.strategyId
      ? postionTableRowData?.strategyId
      : updateData !== undefined
      ? updateData?.positionCompKey?.strategyId
      : selectedStrategy,
    broker: postionTableRowData?.broker
      ? postionTableRowData?.broker
      : updateData !== undefined
      ? updateData?.positionCompKey?.broker
      : selectedBroker,
    exchange: "",
    transactionType: trade,
    quantity: updateData !== undefined ? updateData?.quantity : null,
    product:
      updateData !== undefined
        ? updateData?.product
        : type === "EQ"
        ? "CNC"
        : "NRML",
    validity: updateData !== undefined ? updateData?.validity : "DAY",
    minutes: updateData !== undefined ? updateData?.minutes : null,
    stopLossLimitPrice:
      updateData !== undefined ? updateData?.stopLossLimitPrice : null,
    triggerPrice: updateData !== undefined ? updateData?.triggerPrice : null,
    limitPrice: updateData !== undefined ? updateData?.limitPrice : null,
    activationPoints:
      updateData !== undefined ? updateData?.activationPoints : null,
    trailingTriggerPoints:
      updateData !== undefined ? updateData?.trailingTriggerPoints : null,
    trailingLimitPoints:
      updateData !== undefined ? updateData?.trailingLimitPoints : null,
    tradingSymbol: autoSquareTradingSymbol
      ? autoSquareTradingSymbol
      : !zen
      ? updateData !== undefined
        ? updateData?.tradingSymbol
        : null
      : zen,
    orderType: updateData !== undefined ? updateData?.orderType : null,
    methodType: "POST",
    zenId: postionTableRowData?.zenId
      ? postionTableRowData.zenId
      : updateData !== undefined
      ? updateData?.positionCompKey?.zenSecId
      : "",
  });
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    watch,
    setError,
    setValue,
    getValues,
  } = useForm({
    defaultValues: formData,
  });
  const {
    handleDialogClose,
    handleFormSubmit,
    handleChange,
    handleTypeChange,
    onSubmit,
    handleClose,
  } = useFormAction(setError, setFormOpen, setZen, reset, setValue);

  //  useEffect(() => setValue("quantity", optionChainLotSize) [optionChainLotSize])

  useEffect(() => {
    if (type === "EQ") {
      if (!orderExchange || orderExchange === "NFO") {
        setValue("exchange", "NSE");
        dispatch(setExchange("NSE"));
      }
      setValue("product", "CNC");
    } else {
      setValue("exchange", "NFO");
      dispatch(setExchange("NFO"));
      setValue("product", "NRML");
    }
  }, [type]); // Re-run whenever broker changes
  //It should be up otherwise the editform data will be overrided by this

  //Edit order data setup
  useEffect(() => {
    if (updateData) {
      dispatch(setExchangeList([updateData?.exchange]));
      dispatch(setExchange(updateData?.exchange));
      setValue("exchange", updateData?.exchange);
      const selectedBroker = brokers.find(
        (broker) => broker.broker_name === updateData?.positionCompKey?.broker
      );
      setValue("broker", updateData?.positionCompKey?.broker);
      if (selectedBroker) {
        dispatch(selectBroker(selectedBroker));
      }

      dispatch(selectAccount(updateData?.positionCompKey?.accountId)); // Set selected account
      setValue("accountId", updateData?.positionCompKey?.accountId);

      dispatch(selectStrategy(updateData?.positionCompKey?.strategyId)); // Set selected strategy
      setValue("strategyId", updateData?.positionCompKey?.strategyId);

      setValue("zenId", updateData?.positionCompKey?.zenSecId);
      setValue("product", updateData?.product);

      if (updateData?.securityType === "EQ") {
        dispatch(setType("EQ"));
      } else if (
        updateData?.securityType === "CE" ||
        updateData?.securityType === "PE"
      ) {
        dispatch(setType("OPT"));
        dispatch(
          setLots(Math.ceil(updateData?.quantity / updateData?.lotSize))
        );
      } else if (updateData?.securityType === "FUT") {
        dispatch(
          setLots(Math.ceil(updateData?.quantity / updateData?.lotSize))
        );
        dispatch(setType("FUT"));
      }
      if (updateData?.lotSize) {
        dispatch(setLotSize(updateData?.lotSize));
        setValue("quantity", updateData?.quantity);
      }
    }
  }, []);

  useEffect(() => {
    if (brokers.length == 1) {
      dispatch(selectBroker(brokers[0]));
      // setValue("broker", selectBroker.broker_name);
    }
  }, [brokers]);

  useEffect(() => {
    if (
      selectedBroker !== "" &&
      accounts[selectedBroker?.broker_id]?.length === 1
    ) {
      dispatch(
        selectAccount(accounts[selectedBroker?.broker_id][0].account_id)
      );
      // setValue("accountId", accounts[selectedBroker?.broker_id][0].account_id)
    }
  }, [accounts, selectedBroker]);

  useEffect(() => {
    if (strategies[selectedAccount]?.length === 1) {
      dispatch(selectStrategy(strategies[selectedAccount][0]?.strategy_id));
      // setValue("strategyId",strategies[selectedAccount][0]?.strategy_id)
    }
  }, [strategies, selectedAccount]);

  useEffect(() => {
    function filteredOrderType() {
      if (isUpdateForm && updateData !== undefined && updateData !== null) {
        if (updateData?.orderType === "MARKET") return OrderType;
        else return [updateData?.orderType, "MARKET"];
      }
      return OrderType;
    }
    if (updateData) orderType = filteredOrderType();
    else orderType = OrderType;
  }, []);

  const broker = watch("broker");
  const qty = watch("quantity");

  const strategyIdf = watch("strategyId");
  const accountIdf = watch("accountId");

  const { errors } = formState;

  const filterOptions = (options, { inputValue }) => {
    const results = options
      .filter((option) => option.title.startsWith(inputValue.toUpperCase()))
      .slice(0, 2000);
    return results;
  };

  //positions data type and lot size setter
  useEffect(() => {
    if (postionTableRowData) {
      dispatch(setExchangeList(postionTableRowData?.exchanges));

      if (postionTableRowData?.securityType === "EQ") {
        dispatch(setType("EQ"));
        const orderFormExchange =
          postionTableRowData?.exchanges?.length === 1
            ? postionTableRowData?.exchanges[0]
            : "NSE";
        dispatch(setExchange(orderFormExchange));
        setValue("exchange", orderFormExchange);
      } else {
        dispatch(setExchange(postionTableRowData?.exchanges[0]));
        setValue("exchange", postionTableRowData?.exchanges[0]);
        if (
          postionTableRowData?.securityType === "CE" ||
          postionTableRowData?.securityType === "PE"
        ) {
          dispatch(setType("OPT"));
        } else if (postionTableRowData?.securityType === "FUT") {
          dispatch(setType("FUT"));
        }
      }
      if (postionTableRowData?.lotSize) {
        dispatch(setLotSize(postionTableRowData?.lotSize));
        setValue("quantity", postionTableRowData?.lotSize * lots);
      }
    }
  }, [postionTableRowData]);

  function sortOptions(options) {
    return options.sort((a, b) => {
      // Extract the option type (PE or CE) from the title
      const aType = a.title.slice(-2);
      const bType = b.title.slice(-2);

      // Extract the trading symbol without the option type
      const aSymbol = a.title.slice(0, -2);
      const bSymbol = b.title.slice(0, -2);

      // If the symbols are different, sort by symbol
      if (aSymbol < bSymbol) return -1;
      if (aSymbol > bSymbol) return 1;

      // If the symbols are the same, prioritize CE over PE
      if (aType === "CE" && bType === "PE") return -1;
      if (aType === "PE" && bType === "CE") return 1;

      // If both are same (both PE or both CE), maintain original order
      return 0;
    });
  }

  function filterData() {
    let list = [];
    zenSecuritiesData1
      .then((data) => {
        if (type === "OPT") {
          data["PE"].map((item, v) => {
            list.push({
              title: item.trading_symbol,
              lotSize: item.lot_size,
              zenId: item.zen_id,
              index: v,
              exchanges: item.exchanges,
            });
          });
          data["CE"].map((item, v) => {
            list.push({
              title: item.trading_symbol,
              lotSize: item.lot_size,
              zenId: item.zen_id,
              index: v,
              exchanges: item.exchanges,
            });
          });
          list = sortOptions(list);
        } else {
          data[type]?.map((item, v) => {
            list.push({
              title: item.trading_symbol,
              lotSize: item.lot_size,
              zenId: item.zen_id,
              index: v,
              exchanges: item.exchanges,
            });
          });
        }
      })
      .then(() => {
        dispatch(setSecurities(list));
      });
  }
  //Filtering data
  //If security is null then user should not able to enter lots or quantity
  //For tracking that
  useEffect(() => {
    filterData();
    dispatch(setIsSecuritySelect(false));
    if (!updateData) {
      dispatch(setLots(1));
    }
  }, [type]);

  useEffect(() => {
    if (autocompleteValue === "") {
      dispatch(setIsSecuritySelect(false));
    } else {
      dispatch(setIsSecuritySelect(true));
    }
  }, [autocompleteValue]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        hideForm(updateData, setUpdateData);
        dispatch(setTradingSymbol(""));
        dispatch(setShowOrderForm(false));
        handleClose(hideForm);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (isLotsFocus) {
      setValue("quantity", lotSize * lots);
    }
  }, [lots, lotSize]);

  useEffect(() => {
    if (qty !== undefined && isQtyFocus) {
      const newlot = Math.floor(qty / lotSize);
      dispatch(setLots(newlot));
    }
  }, [qty, lotSize]);

  useEffect(() => {
    if (updateData?.orderType) {
      handleChange(updateData.orderType, updateData, setUpdateData, dispatch);
    }
  }, [updateData?.orderType]);

  useEffect(() => {
    let lotsData;
    if (optionChainTradingSymbol !== "") {
      zenSecuritiesData1
        .then((data) => {
          if (optionChainTradingSymbol.includes("CE")) {
            lotsData = data["CE"]?.find(
              (item) => item.trading_symbol === optionChainTradingSymbol
            );
          } else if (optionChainTradingSymbol.includes("PE")) {
            lotsData = data["PE"]?.find(
              (item) => item.trading_symbol === optionChainTradingSymbol
            );
          }
        })
        .then(() => {
          dispatch(setExchangeList(lotsData?.exchanges));
          dispatch(setExchange(lotsData?.exchanges[0]));
          setValue("exchange", lotsData?.exchanges[0]);
          dispatch(setLotSize(lotsData?.lot_size));
          setValue("zenId", lotsData?.zen_id);
        })
        .then(() => {
          setValue("quantity", lotsData?.lot_size * lots);
        });
    }
  }, [optionChainTradingSymbol]);

  useEffect(() => {
    let lotsData;
    if (graphTradingSymbol && graphTradingSymbol !== "") {
      let lotsData;

      zenSecuritiesData1
        .then((data) => {
          lotsData = data["EQ"]?.find(
            (item) => item.trading_symbol === graphTradingSymbol
          );
        })
        .then(() => {
          dispatch(setLotSize(lotsData?.lot_size));
          dispatch(setExchangeList(lotsData?.exchanges));
          dispatch(
            setExchange(
              lotsData?.exchanges.length === 1 ? lotsData?.exchanges[0] : "NSE"
            )
          );
          setValue(
            "exchange",
            lotsData?.exchanges.length === 1 ? lotsData?.exchanges[0] : "NSE"
          );
          dispatch(setType("EQ"));
          setValue("exchange", "");
          setValue("zenId", lotsData?.zen_id);
          setValue("quantity", lotsData?.lot_size * lots);
        });
    }
  }, [graphTradingSymbol]);

  return (
    <>
      <OrderFormTemplate
        updateData={updateData}
        setUpdateData={setUpdateData}
        setFormOpen={setFormOpen}
        trade={trade}
        zen={zen}
        handleDialogClose={handleDialogClose}
        handleFormSubmit={handleFormSubmit}
        handleSubmit={handleSubmit}
        setError={setError}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        OrderType={orderType}
        control={control}
        orderValue={orderValue}
        handleChange={handleChange}
        Prices={Prices}
        broker={broker}
        setFormData={setFormData}
        hideForm={hideForm}
        filterOptions={filterOptions}
        handleTypeChange={handleTypeChange}
        sortOptions={sortOptions}
        filterData={filterData}
        exchange={orderExchange}
        handleClose={handleClose}
        fData={[accountIdf, strategyIdf]}
        TriggerPoints={TriggerPoints}
      />
    </>
  );
}
export default OrderForm;
