/* eslint-disable */

import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import DerivativeGraphTempalte from "components/DerivativeGraph/container/DerivativeGraphTemplate";
import HomePage from "components/HomePage/HomePage.jsx";
import ProtectedRoute from "ProtectedRoute.js";
import { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { apiConfig } from "apiConfig.js";

import VerifyEmailTemplate from "components/VerifyEmail/VerifyEmailTemplate.jsx";
// import useWebSocketConnection from "services/WebSocketConnection.js";
import useIdleCheck from "services/IdleCheck.js";
const App = () => {
  const origin = window.location.origin;
  // const { connectWebSocket } = useWebSocketConnection();
  const { resetTimer } = useIdleCheck();
  const { isAuthenticated } = useAuth0();
  const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(
    origin
  );

  const element = useSelector((state) => state.graphTemplate.elementCopy);
  const timeoutRef = useRef(null);

  // Redirect user to Auth0 MFA

  useEffect(() => {
    window.addEventListener("mousemove", (timeoutRef) =>
      resetTimer(timeoutRef)
    );
    window.addEventListener("keydown", (timeoutRef) => resetTimer(timeoutRef));

    // Start the timer
    resetTimer(timeoutRef);

    return () => {
      window.removeEventListener("mousemove", (timeoutRef) =>
        resetTimer(timeoutRef)
      );
      window.removeEventListener("keydown", (timeoutRef) =>
        resetTimer(timeoutRef)
      );
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  // useEffect(() => {
  //   let socket;
  //   if (isAuthenticated) socket = connectWebSocket();
  //   return () => {
  //     if (socket) socket.close();
  //   }; // Cleanup on unmount
  // }, [isAuthenticated]);

  return (
    <Routes>
      <Route path="/home" element={<HomePage />} />
      {isInternalIP ? (
        <Route
          path="/"
          element={<Navigate to="/default/dashboard" replace />}
        />
      ) : (
        <Route path="/" element={<Navigate to="/home" replace />} />
      )}
      <Route
        path="/default/*"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      />
      <Route
        path="/verifyEmail"
        element={
          <ProtectedRoute>
            <VerifyEmailTemplate />
          </ProtectedRoute>
        }
      />

      <Route
        path="/default/derivative/:equityName"
        element={
          <ProtectedRoute>
            <DerivativeGraphTempalte elements={element} />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
