/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import coseBilkent from 'cytoscape-cose-bilkent';
import styleSheet from '../presentation/cytoscapeStylesheet';
import PopoverTemplate from './PopoverTemplate';
import { setEquity, setIsReseting, setPopoverNode , setSector, setTargetNode} from '../../GraphTemplate/container/GraphTemplateSlice';
import { setShowPopover, setPopoverPosition, setPopoverIndexLabels, setIsSector, setIsIndex, setDefaultZoom, setFirstLayoutStop, setNodePosition, setNodeStyle, updateNodePosition, setIsGraphLoaded} from './MainTemplateSlice';
import { setIsDerivative } from '../../DerivativeGraph/container/DerivativeGraphSlice';
import { useSelector } from 'react-redux';
import 'cytoscape-qtip';
import $ from 'jquery';
import 'qtip2/dist/jquery.qtip.min.css';

cytoscape.use(require('cytoscape-qtip'));
cytoscape.use(coseBilkent);
const MainPage = (
    {
      elements,
      cyRef,
      dispatch,
      windowSize,
      showChart,
      showPopover,
      layout,
      siblingEquity,
      isIndex,
      handleRightClick,
      checkPossibility,
      options,
      handleLayoutStop,
      isReseting
    }
  ) => 
  { 

    useEffect(() => {
      return () => {
        dispatch(setIsGraphLoaded(false))
      }
    },[]) 

    useEffect(() => {
      if(cyRef.current)
      {
        cyRef.current.layout(layout).run();
        dispatch(setDefaultZoom(cyRef.current.zoom()))
      }

    }, [cyRef, elements]);

    const defaultZoom = useSelector(state => state.mainTemplate.defaultZoom)
    
    useEffect(() => {
      if(cyRef.current && isReseting) 
      {
        cyRef.current.zoom(defaultZoom); 
        cyRef.current.center();
        cyRef.current.nodes().style({
          opacity : 1
        })
        cyRef.current.edges().style({
          'line-opacity' : 1
        })
        dispatch(setEquity(null))
        dispatch(setSector(null))
        dispatch(setIsReseting(false));
      }
    },[isReseting])
      return (
        <div>
        
          <CytoscapeComponent
            elements={elements}
            randomize={false}
            style={
              { 
                width: windowSize.width, 
                height : 0.8 *  windowSize.height, 
                paddingTop : '-1%'
              }
            }
            options = {options}
            cy={(cy) => { 
              cyRef.current = cy;
              cy.nodes().on('cxttap', (e) => handleRightClick(e.target))
              cy.nodes().on('tap', (e) => handleRightClick(e.target))

              cy.maxZoom(1.5)
              cy.minZoom(0.2)
              cy.on('zoom', () => 
              {
                const zoom = cy.zoom()
                if(zoom >= 1.5)
                  cy.zoom(1.5)
                if(zoom <= 0.2)
                  cy.zoom(0.2)
              })
            
              cy.on('click', (e)=> {
                if(e.target === cy)
                  dispatch(setShowPopover(false))
              })
              
              cy.nodes().filter(node => node.data().label === 'Equity').forEach(node => checkPossibility(node));

              cy.on('dragfree', 'node', (event) => dispatch(updateNodePosition([event.target.id(),event.target.position()])))

              cy.on('layoutstop', () => handleLayoutStop(cy))   
              
              cy.on('layoutready', () => dispatch(setIsGraphLoaded(false)))
          
            }
          }
            stylesheet={styleSheet} 
        />
        {
          
          showPopover && 
            <PopoverTemplate
              cyRef = {cyRef}
              elements={elements}
              isIndex = {isIndex}
            />
          
        }
      </div>
          
      );

}

export default MainPage;
