/*eslint-disable*/
import React, { Component } from "react";
import Dashboard from "views/Dashboard.js";
import Positions from "views/Positions.js";
import Pnl from "views/Pnl.js";
import Trades from "views/Trades";
import Orders from "views/Orders.js";
import {
  CandlestickChart,
  ListOrdered,
  SearchCodeIcon,
  TrendingUp,
  Wallet,
} from "lucide-react";
import { ImTree } from "react-icons/im";
import GreeksAnalyzer from "views/GreeksAnalyzer";
import OptionChainTemplate from "components/OptionChain/OptionChainTemplate";
import ExternalTrades from "components/ExternalTrades/ExternalTrades";

var routes = [
  {
    path: "/orders",
    name: "Orders ",
    icon: <Wallet />,
    component: <Orders />,
    layout: "/default",
    roles: ["TRADER"],
  },
  {
    path: "/trades",
    name: "Trades",
    icon: <CandlestickChart />,
    component: <Trades />,
    layout: "/default",
    roles: ["TRADER"],
  },
  {
    path: "/positions",
    name: "Positions",
    icon: <ListOrdered />,
    component: <Positions />,
    layout: "/default",
    roles: ["READ_POSITION"],
  },
  {
    path: "/pnl",
    name: "PnL",
    icon: <TrendingUp />,
    component: <Pnl />,
    layout: "/default",
    roles: ["READ_PNL"],
  },
  {
    path: "/greeks",
    name: "Greeks Analyzer",
    icon: <SearchCodeIcon />,
    component: <GreeksAnalyzer />,
    layout: "/default",
    roles: ["TRADER", "READ_PNL", "READ_POSITION"],
  },
  {
    path: "/dashboard",
    name: "Zen Security Analyzer",
    icon: <ImTree />,
    component: <Dashboard />,
    layout: "/default",
    roles: [],
  },
  {
    path: "/optionChain",
    name: "Option Chain",
    icon: "O",
    component: <OptionChainTemplate />,
    layout: "/default",
    roles: ["TRADER", "READ_PNL", "READ_POSITION"],
  },
  // {
  //   path: "/externalTrades",
  //   name: "External Trades",
  //   icon: "E",
  //   component: <ExternalTrades/>,
  //   layout: "/default",
  // }
];

export default routes;
