
import { createSlice } from '@reduxjs/toolkit';


const ProtectedRouteSlice = createSlice({
    name: 'protectedRoute',
    initialState: {
        accessToken : null
    },
    reducers: {
        
        setAccessToken(state,action){
            state.accessToken = action.payload
        }

    },
});

export const { setAccessToken } = ProtectedRouteSlice.actions;

export default ProtectedRouteSlice.reducer;
