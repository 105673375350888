/* eslint-disable */
import React, { useState, useEffect } from "react";
import MainTemplate from "../../CytoscapeGraph/container/MainTemplate";
import DropDown from "../../Main/DropDown";
import ChartTemplate from "../../Chart/container/ChartTemplate";
import "../presentation/GraphTemplate.css";
import {
  setWindowSize,
  setShowDerivative,
  setShowChart,
  setShowForm,
  setGraphResult,
  setElementCopy,
  setBackgroundColor,
  setIsReseting,
} from "./GraphTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import OrderForm from "../../OrderForm/Field/OrderForm";
import {
  setChartData,
  setChartIndexData,
  setIsIndex,
  setPopoverPosition,
  setShowPopover,
} from "../../CytoscapeGraph/container/MainTemplateSlice";
import { IoMdClose } from "react-icons/io";
import { DraggableComponent } from "components/OrderForm/Field/draggable";
import Toasts from "components/Toasts/Toasts";
import { TbReload } from "react-icons/tb";

let element;
const GraphTemplate = () => {
  const [elements, setElements] = useState([]);

  const dispatch = useDispatch();
  const showChart = useSelector((state) => state.graphTemplate.showChart);
  const targetNode = useSelector((state) => state.graphTemplate.targetNode);
  const showForm = useSelector((state) => state.graphTemplate.showForm);
  const popoverNode = useSelector((state) => state.graphTemplate.popoverNode);
  const popoverPosition = useSelector(
    (state) => state.mainTemplate.popoverPosition
  );
  const backgroundColor = useSelector(
    (state) => state.graphTemplate.backgroundColor
  );

  useEffect(() => {
    dispatch(setElementCopy(structuredClone(elements)));
    element = elements;
  }, [elements]);
  const hideForm = () => {
    dispatch(setShowForm(false));
  };
  const hideChart = () => {
    dispatch(setShowChart(false));
  };
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      dispatch(setShowPopover(false));
      dispatch(setShowForm(false));
    }
  });
  useEffect(() => {
    const updateWindowSize = () => {
      dispatch(
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );

      popoverNode.forEach((popoverNodeItem) =>
        dispatch(
          setPopoverPosition({
            x: popoverNodeItem.renderedPosition().x + 60,
            y: popoverNodeItem.renderedPosition().y - 40,
          })
        )
      );
    };
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, [popoverNode, popoverPosition]);

  const resetGraphHandler = () => {
    dispatch(setIsReseting(true));
  };

  useEffect(() => {
    dispatch(setShowPopover(false));
    dispatch(setShowForm(false));
  }, []);

  return (
    <div>
      <div>
        <div className="flex flex-wrap justify-start gap-4 w-full mt-20">
          {/* Dropdown takes full width on small screens, auto on larger */}
          <div className="w-full md:w-auto flex-1">
            <DropDown elements={elements} />
          </div>

          <button
            type="button"
            className="w-full md:w-auto lg:w-[10%] flex items-center gap-x-2 bg-gray-200 border border-white rounded-lg px-4 py-2 h-12 cursor-pointer whitespace-nowrap min-w-36"
            onClick={resetGraphHandler}
          >
            <TbReload size={28} className="text-green-600" /> Reset Graph
          </button>
        </div>

        <div id="graphTemplate">
          <div id="securityGraph">
            <MainTemplate
              elements={elements}
              setElements={setElements}
              setGraphResult={setGraphResult}
            />
          </div>
          {showChart && (
            <div id="comparisionChart">
              <div className="btn-close" onClick={hideChart}>
                <IoMdClose
                  style={{
                    position: "absolute",
                    right: "3%",
                    top: "6%",
                    zIndex: 1,
                    backgroundColor: backgroundColor,
                  }}
                  onMouseEnter={() => dispatch(setBackgroundColor("white"))}
                  onMouseLeave={() =>
                    dispatch(setBackgroundColor("transparent"))
                  }
                  onClick={() => {
                    dispatch(setChartData([]));
                    dispatch(setChartIndexData([]));
                  }}
                />
              </div>
              <ChartTemplate />
            </div>
          )}
        </div>
      </div>

      {showForm && (
        <div id="form">
          <DraggableComponent>
            <OrderForm
              targetNode={popoverNode.data().properties.name}
              trade="BUY"
              setFormOpen={setShowForm}
              hideForm={hideForm}
            />
          </DraggableComponent>
        </div>
      )}
      <Toasts />
    </div>
  );
};
export default GraphTemplate;
export { element };
