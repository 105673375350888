/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  setEquity,
  setEquityValues,
  setSector,
} from "../GraphTemplate/container/GraphTemplateSlice";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";

const DropDown = ({ elements }) => {
  const getUniqueValues = (elements) => {
    const uniqueIds = new Set();
    const uniqueData = elements.filter((data) => {
      if (uniqueIds.has(data.data.id)) return false;
      else {
        uniqueIds.add(data.data.id);
        return true;
      }
    });
    return uniqueData;
  };

  const equity = useSelector((state) => state.graphTemplate.equity);
  const equityValues = useSelector((state) => state.graphTemplate.equityValues);
  const sector = useSelector((state) => state.graphTemplate.sector);
  const sectorValues = getUniqueValues(
    elements.filter((element) => element.data.label === "Sector")
  ).map((value) => value.data.properties.name);
  sectorValues.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const dispatch = useDispatch();
  useEffect(() => {
    if (sector === null)
      dispatch(
        setEquityValues(
          elements
            .filter((element) => element.data.label === "Equity")
            .map((value) => value.data.properties.fullname)
        )
      );
    else
      dispatch(
        setEquityValues(
          elements
            .filter((data) => data.data.parent === sector)
            .map((value) => value.data.properties.fullname)
        )
      );
  }, [elements]);
  useEffect(() => {
    if (sector === null)
      dispatch(
        setEquityValues(
          elements
            .filter((element) => element.data.label === "Equity")
            .map((value) => value.data.properties.fullname)
        )
      );
    else
      dispatch(
        setEquityValues(
          elements
            .filter((data) => data.data.parent === sector)
            .map((value) => value.data.properties.fullname)
        )
      );
  }, [sector]);

  return (
    <form className="w-full">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/2">
          <Autocomplete
            disablePortal
            id="combo-box-equity"
            options={equityValues}
            value={equity}
            onChange={(event, value) => dispatch(setEquity(value))}
            sx={{
              width: "100%",
              "& .MuiAutocomplete-inputRoot": { height: 48 },
              "& .MuiInputBase-root": { padding: "0px 8px" },
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select an equity" />
            )}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Autocomplete
            disablePortal
            id="combo-box-sector"
            value={sector}
            onChange={(e, value) => dispatch(setSector(value))}
            sx={{
              width: "100%",
              "& .MuiAutocomplete-inputRoot": { height: 48 },
              "& .MuiInputBase-root": { padding: "0px 8px" },
            }}
            options={sectorValues}
            renderInput={(params) => (
              <TextField {...params} label="Select a sector" />
            )}
          />
        </div>
      </div>
    </form>
  );
};

export default DropDown;
