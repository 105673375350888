/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedClients: null,
  isDropdown: false,
  isFilterDropdown: false,
};

const DemoNavbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setSelectedClients(state, action) {
      state.selectedClients = action.payload;
    },
    toggleDropdown(state, action) {
      state.isDropdown = action.payload;
    },
    toggleFilterDropdown(state, action) {
      state.isFilterDropdown = action.payload;
    },
  },
});

export const { setSelectedClients, toggleDropdown, toggleFilterDropdown } =
  DemoNavbarSlice.actions;
export default DemoNavbarSlice.reducer;
