/* eslint-disable */
import OrderForm from "components/OrderForm/Field/OrderForm";
import { DraggableComponent } from "components/OrderForm/Field/draggable";
import { useGlobalState } from "components/globalVariable";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveTradingSymbol,
  saveFormOpen,
  saveOpen,
  saveIsConfirmed,
  saveOrderDetails,
  savePositionZenId,
} from "../container/PositionActionSlice";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import getAvailableMargin from "components/Margin/container/cypherResult";
import { setAvailableMargin } from "components/Margin/container/MarginTemplateSlice";
import fetchBetaValues from "components/Beta/getPortfoliobeta";
import { apiConfig } from "apiConfig";
import { useFetchData } from "utils/customFetch";
import API_ENDPOINTS from "services/api";

export default function PositionActions() {
  const { fetchData } = useFetchData();
  const [formOpen, setFormOpen] = useState(false);
  const formOpen2 = useSelector((state) => state.positionActions.formOpen);
  const orderType = useSelector((state) => state.positionActions.type);
  const open = useSelector((state) => state.positionActions.open);
  const orderData = useSelector((state) => state.positionActions.orderData);
  const isConfirmed = useSelector((state) => state.positionActions.isConfirmed);
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const { selectedAccount } = useSelector((state) => state.clientSelection);

  const dispatch = useDispatch();

  const orderDetails = useSelector(
    (state) => state.positionActions.orderDetails
  );

  const hideForm = (formData, setUpdateData) => {
    if (formData !== null && formData !== undefined) {
      dispatch(setUpdateData(null));
    } else {
      setFormOpen((formOpen) => !formOpen);
    }
    dispatch(saveFormOpen(false));
    dispatch(saveTradingSymbol(""));
    dispatch(saveOrderDetails(""));
    dispatch(savePositionZenId(""));
  };

  useEffect(() => {
    if (isConfirmed) {
      const placeOrder = async () => {
        try {
          const header = {
            Accept: "application/json",
            "Content-Type": "application/json",
          };
          const response = await fetchData(
            API_ENDPOINTS.PLACE_ORDER(),
            "POST",
            header,
            orderData
          );
          dispatch(saveFormOpen(false));
          dispatch(saveTradingSymbol(""));
          dispatch(savePositionZenId(""));
          getAvailableMargin(
            fetchData,
            clientId,
            selectedAccount,
            dispatch,
            setAvailableMargin
          );
          fetchBetaValues(fetchData, clientId, accountIds, dispatch);
        } catch (error) {
          alert("An error occurred: " + error.message);
        }
      };

      placeOrder();
    }
    dispatch(saveIsConfirmed(false));
  }, [isConfirmed]);

  return (
    <div>
      {formOpen2 ? (
        <div
          style={{
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            display: "flex",
            zIndex: 1000,
          }}
        >
          <DraggableComponent>
            <OrderForm
              setFormOpen={setFormOpen}
              trade={orderType}
              hideForm={hideForm}
            />
          </DraggableComponent>
        </div>
      ) : null}
      <React.Fragment>
        <Dialog
          open={open}
          onClose={() => saveOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"This action cannot be undone"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to auto square off <b>{orderDetails?.symbol}</b> ?
            </DialogContentText>
            <br />
            <DialogContentText id="alert-dialog-details">
              <p className="text-md">Order details</p>
              <p className="text-md">
                Transaction type :{" "}
                <b
                  className={`${
                    orderDetails?.type === "BUY"
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {orderDetails?.type}
                </b>
              </p>
              <p className="text-md">
                Quantity : <b>{orderDetails?.quantity}</b>
              </p>
              <p className="text-md">
                Order type : <b>{orderDetails?.orderType}</b>
              </p>
              <p className="text-md">
                Strategy Id : <b>{orderDetails?.strategyId}</b>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch(saveIsConfirmed(false));

                dispatch(saveOpen(false));
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                dispatch(saveIsConfirmed(true));

                dispatch(saveOpen(false));
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}
