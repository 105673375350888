import { setBeta } from "./BetaSlice";
import API_ENDPOINTS from "../.././services/api";

const fetchBetaValues = async (fetchData, clientId, accountIds, dispatch) => {
  // Get today's date and format it
  const today = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
  const timestamp = `${today} 23:00:00`;

  const body = {
    startTimestamp: timestamp,
    endTimestamp: timestamp,
    positionCompKeyFilter: {
      clientId: clientId,
      strategyIds: [1],
      accountIds: accountIds,
    },
  };

  try {
    const data = await fetchData(
      API_ENDPOINTS.GET_PORTFOLIO_BETA(),
      "POST",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body
    );
    dispatch(setBeta(data[0]?.portfolioBeta?.toFixed(3)));
  } catch (error) {
    console.error("Error fetching beta values:", error);
  }
};

export default fetchBetaValues;
