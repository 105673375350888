/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showPnlChart: false,
  closePnlChart: true,
  pnlChartData: null,
};

const PnlChartSlice = createSlice({
  name: "pnlChart",
  initialState,
  reducers: {
    setShowPnlChart(state, action) {
      state.showPnlChart = action.payload;
    },
    setPnlChartData(state, action) {
      state.pnlChartData = action.payload;
    },
    setClosePnlChart(state, action) {
      state.closePnlChart = action.payload;
    },
  },
});

export const { setPnlChartData, setShowPnlChart, setClosePnlChart } =
  PnlChartSlice.actions;
export default PnlChartSlice.reducer;
