/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formOpen: true,
  orderType: null,
  autocompleteValue: "",
  securities: [],
  lotSize: 1,
  lots: 1,
  type: "EQ",
  isSecuritySelect: false,
  isQtyFocus: false,
  isLotsFocus: false,
  open: false,
  pendingData: null,
  exchange: null,
  exchangeList: null,
  orderValue: [],
  updateData: undefined,
  isUpdateForm: false,
  isDeleteBtn: false,
  refreshTriggerCount: 0,
  showOrderResponse: false,
  orderMessage: null,
  orderStatus: null,
  enableTriggerPoints: false,
};

const orderFormSlice = createSlice({
  name: "orderForm",
  initialState,
  reducers: {
    setFormOpen(state, action) {
      state.formOpen = action.payload;
    },
    setOrderType(state, action) {
      state.orderType = action.payload;
    },
    setAutocompleteValue(state, action) {
      state.autocompleteValue = action.payload;
    },
    setSecurities(state, action) {
      state.securities = action.payload;
    },
    setLotSize(state, action) {
      state.lotSize = action.payload;
    },
    setLots(state, action) {
      state.lots = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setIsSecuritySelect(state, action) {
      state.isSecuritySelect = action.payload;
    },
    setQtyFocus(state, action) {
      state.isQtyFocus = action.payload;
    },
    setLotsFocus(state, action) {
      state.isLotsFocus = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setPendingData(state, action) {
      state.pendingData = action.payload;
    },
    setExchange(state, action) {
      state.exchange = action.payload;
    },
    setExchangeList(state, action) {
      state.exchangeList = action.payload;
    },
    setOrderValue(state, action) {
      state.orderValue = action.payload;
    },
    setUpdateData(state, action) {
      state.updateData = action.payload;
    },
    setIsUpdateForm(state, action) {
      state.isUpdateForm = action.payload;
    },
    setIsDeleteBtn(state, action) {
      state.isDeleteBtn = action.payload;
    },
    setRefreshTriggerCount(state, action) {
      state.refreshTriggerCount = action.payload;
    },
    setShowOrderResponse(state, action) {
      state.showOrderResponse = action.payload;
    },
    setOrderMessage(state, action) {
      state.orderMessage = action.payload;
    },
    setOrderStatus(state, action) {
      state.orderStatus = action.payload;
    },
    setEnableTriggerPoints(state, action) {
      state.enableTriggerPoints = action.payload;
    },
  },
});

export const {
  setFormOpen,
  setOrderType,
  setAutocompleteValue,
  setSecurities,
  setLotSize,
  setLots,
  setType,
  setIsSecuritySelect,
  setQtyFocus,
  setLotsFocus,
  setOpen,
  setPendingData,
  setExchangeList,
  setOrderValue,
  setUpdateData,
  setIsUpdateForm,
  setIsDeleteBtn,
  setRefreshTriggerCount,
  setShowOrderResponse,
  setOrderMessage,
  setOrderStatus,
  setEnableTriggerPoints,
  setExchange,
} = orderFormSlice.actions;
export default orderFormSlice.reducer;
