import React from "react";

const UnrealizedPnlCellRenderer = (props) => {
  if (props.value > 0) {
    return (
      <span className="inline-flex items-center px-2 py-1 leading-[1rem] text-[8px] md:text-[10px] lg:text-[12px] font-medium text-green-700 ">
        {props.valueFormatter({ value: props.value })}
      </span>
    );
  } else if (props.value < 0) {
    return (
      <span className="inline-flex items-center px-2 py-1 leading-[1rem] text-[8px] md:text-[10px] lg:text-[12px] font-medium text-red-700">
        {props.valueFormatter({ value: props.value })}
      </span>
    );
  } else if (props.value === 0)
    return (
      <span className="inline-flex items-center px-2 py-1 leading-[1rem] text-[8px] md:text-[10px] lg:text-[12px] font-medium text-blue-700">
        {props.valueFormatter({ value: props.value })}
      </span>
    );

  return null;
};

export default UnrealizedPnlCellRenderer;
