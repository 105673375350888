/* eslint-disable */
import MainPage from "./MainPage"
import { useRef, useEffect } from "react";
import getData from "./CypherResult";
import getLayout from '../presentation/cytoscapeLayout';
import { useSelector, useDispatch } from "react-redux";
import applyFilter from "../presentation/CytoscapeFilter";
import { setFirstLayoutStop, setIsGraphLoaded, setIsIndex, setIsSector, setNodePosition, setPopoverIndexLabels, setPopoverPosition, setShowPopover } from "./MainTemplateSlice";
import { setPopoverNode } from "components/GraphTemplate/container/GraphTemplateSlice";
import { setIsDerivative } from "components/DerivativeGraph/container/DerivativeGraphSlice";
import { useFetchData } from "utils/customFetch";
const MainTemplate = ({elements, setElements,setGraphResult}) => {
  const { fetchData } = useFetchData();
  const sector = useSelector(state => state.graphTemplate.sector)
  const equity = useSelector(state => state.graphTemplate.equity)
  const dispatch = useDispatch()
  const windowSize = useSelector(state => state.graphTemplate.windowSize)
  const showChart = useSelector(state => state.graphTemplate.showChart)
  const showPopover = useSelector(state => state.mainTemplate.showPopover)
  const siblingEquity = useSelector(state => state.mainTemplate.siblingEquity)
  const layout = getLayout(showChart)
  const isIndex = useSelector(state => state.mainTemplate.isIndex)
  const isGraphLoaded = useSelector(state => state.mainTemplate.isGraphLoaded)
  const cyRef = useRef(null)
  const firstLayoutStop = useSelector(state => state.mainTemplate.firstLayoutStop)
  const nodePosition = useSelector(state => state.mainTemplate.nodePosition)
  const nodeStyle = useSelector(state => state.mainTemplate.nodeStyle);
  const isReseting = useSelector(state => state.graphTemplate.isReseting);
      
      useEffect(() => {
        getData(fetchData, setElements, setGraphResult, dispatch)
      }, []);    
     
      useEffect(() => {
        if(elements.length >0  && isGraphLoaded)
          applyFilter(cyRef, sector, equity)
      },[equity,sector, cyRef,elements, isGraphLoaded])

      const handleRightClick = (node) => {
        dispatch(setPopoverNode(node)) 
        dispatch(setPopoverPosition({ x : node.renderedPosition().x + 25, y :windowSize.height - node.renderedPosition().y < 338 ?node.renderedPosition().y - 180: node.renderedPosition().y - 40}))
        dispatch(setShowPopover(true))
        dispatch(setIsIndex(node.data().label === 'Index' ? true : false))
        dispatch(setIsSector(node.data().label === 'Sector' ? true : false))
        dispatch(setIsDerivative(false))

        const labels = node.parent().neighbourhood().nodes().map(element => element.data().properties.name)
        // if(labels[0] === 'NIFTY IT' || labels[0] === 'NIFTY BANK') labels.push('NIFTY 50')
        dispatch(setPopoverIndexLabels(node.data().label === 'Index' ? [node.data().properties.name] : [...labels]))
      }

      const getPosition = (x,y) => {
        const position = {x : 0, y : y }
        if(Math.abs((0.8 * windowSize.width) - x )> x)
          position.x = x - 300
        else
          position.x = x + 300
        return position
      }

      const applyQTip = (node) => {
        node.qtip({
          content: `Name : ${node.data().properties.name}<br/>Sector : ${node.data().properties.industry}`,
          show: {
              event: 'click',
              solo : true
          },
          hide: {
              event: 'unfocus'
          },
          style: {
            classes: 'qtip-bootstrap',
            tip: {
              width: 16,
              height: 8
            }
          }
      });
      
      }

      const checkPossibility = (node) => {
        if(showPopover === false)
        {
          applyQTip(node);
        }
      }
      
      const options = {
        userZoomingEnabled: true, 
        userPanningEnabled: true, 
        boxSelectionEnabled: false,
        autoungrabify : false,
        wheelSensitivity : 0.000001,
        autounselectify : false,
        motionBlur: true, // Enable motion blur for smoother zoom
        animation: {
          duration: 500, // Duration of the zoom animation in milliseconds
          easing: 'ease-in-out' // Easing function for the zoom animation
        }
      }

      const handleLayoutStop = (cy) => {
        if(firstLayoutStop)
        {
          const bankPublicPosition = cy.nodes().filter(element => element.data().properties.name === 'Bank - Public').renderedPosition()
          const bankPrivatePosition = cy.nodes().filter(element => element.data().properties.name === 'Bank - Private').renderedPosition()
          if(bankPublicPosition != undefined)
          {
            cy.nodes().filter(element => element.data().properties.name === 'Bank - Public').renderedPosition({x : bankPrivatePosition.x + 90  , y : bankPrivatePosition.y - 150})
            cy.nodes().filter(element => element.data().properties.name === 'NIFTY BANK').renderedPosition({x : bankPrivatePosition.x + 90, y : bankPrivatePosition.y - 20 })
            const position = cy.nodes().filter(node => node.data().id === 'Bank Container').renderedPosition()
            cy.nodes().filter(node => node.data().id === 'Bank Container').renderedPosition({x : position.x - 150, y : position.y - 20})
            cy.nodes().filter(element => element.data().properties.name === 'Bank - Private').renderedPosition({x : bankPrivatePosition.x - 180  , y : bankPrivatePosition.y - 50 })

          }

          const itPosition = cy.nodes().filter(element => element.data().properties.name === 'Information Technology').renderedPosition()
          
          if(itPosition != undefined)
          {
            cy.nodes().filter(element => element.data().properties.name === 'NIFTY IT').renderedPosition({x : itPosition.x + 100, y : itPosition.y})
            const position = cy.nodes().filter(node => node.data().id === 'Information Technology Container').renderedPosition()
            cy.nodes().filter(node => node.data().id === 'Information Technology Container').renderedPosition({x : position.x - 20, y : position.y- 150})
        
          }

          const nodePosition = {}
          cy.nodes().forEach(node => {
            nodePosition[node.id()] = {x : node.position().x , y : node.position().y}
          })
          dispatch(setNodePosition(nodePosition))
          dispatch(setFirstLayoutStop(false))
        }
        else{
          cy.nodes().forEach(node => {
            const nodeId = node.id();
            const newPosition = nodePosition[nodeId];
            if (newPosition) {
                node.position(newPosition);
            }
        });
        
          
        }
        dispatch(setIsGraphLoaded(true))
        cy.fit();            
        cy.center()
      }

    return (
      <>
        <MainPage
          elements = {elements}
          cyRef = {cyRef}
          dispatch = {dispatch}
          windowSize = {windowSize}
          showChart = {showChart}
          showPopover = {showPopover}
          layout = {layout}
          siblingEquity = {siblingEquity}
          isIndex = {isIndex}
          handleRightClick = {handleRightClick}
          checkPossibility = {checkPossibility}
          options = {options}
          handleLayoutStop = {handleLayoutStop}
          isReseting = {isReseting}
        />
      
        
      
      </>
    
    )
}

export default MainTemplate