/* eslint-disable */

import React, { useState } from "react";
import OrderForm from "./OrderForm";
import { useGlobalState } from "components/globalVariable";
import { DraggableComponent } from "./draggable";
import "./OrderForm.css";
import { useDispatch, useSelector } from "react-redux";

export default function OrderFormContainer({ options }) {
  const [formOpen, setFormOpen] = useState(true);
  const [trade, setTrade] = useState("");
  const [clientId] = useGlobalState("clientId");
  const dispatch = useDispatch();
  const hideForm = (formData, setUpdateData) => {
    if (formData !== null && formData !== undefined) {
      dispatch(setUpdateData(null));
    } else {
      setFormOpen((formOpen) => !formOpen);
    }
  };
  const handleOptionClick = (option) => {
    if (!clientId) {
      alert("Kindly select the trade profile!");
      return;
    }

    setFormOpen(!formOpen);
    setTrade(option);
  };

  return (
    <div className="main_form_container">
      <div>
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => handleOptionClick(option.value)}
            className={`open-btn ${option.color}`}
          >
            {option.label}
          </button>
        ))}
      </div>
      {!formOpen && (
        <div className="flex fixed h-full w-full top-0 left-0 z-50">
          <DraggableComponent>
            <OrderForm
              setFormOpen={setFormOpen}
              trade={trade}
              hideForm={hideForm}
            />
          </DraggableComponent>
        </div>
      )}
    </div>
  );
}
