/*eslint-disable*/
import React, { useState } from "react";

import PnlGrid from "../components/PnlGrid/container/PnlGrid";
import TradesFilter from "../components/TradesFilter/container/TradesFilter";
import store from "../app/store";

// reactstrap components
import {
  UncontrolledAlert,
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

function Pnl() {
  return (
    <>
      <Row>
        <Col xs="auto">
          <PnlGrid />
        </Col>
      </Row>
    </>
  );
}

export default Pnl;
