/* eslint-disable */ 
import { useDispatch, useSelector } from 'react-redux';
import Popover from '../../CytoscapeGraph/container/Popover'
import { setDerivativeIsIndex, setDerivativePopover, setHasFetched, setShowChart } from '../../DerivativeGraph/container/DerivativeGraphSlice';
import { setShowPopover, setSiblingEquity } from '../../CytoscapeGraph/container/MainTemplateSlice';
import { setShowForm } from '../../GraphTemplate/container/GraphTemplateSlice';
import { useState } from 'react';
import { useGlobalState } from 'components/globalVariable';

const PopoverTemplate = ({cyRef}) => {
    const dispatch = useDispatch()
    const cy = cyRef.current
    const popoverPosition = useSelector(state => state.mainTemplate.popoverPosition)
    const popoverNode = useSelector(state => state.derivativeGraphTemplate.popoverNode)
    const isIndex = useSelector(state => state.derivativeGraphTemplate.isIndex)
    const isSector = useSelector(state => state.mainTemplate.isSector)
    const isDerivative = useSelector(state => state.derivativeGraphTemplate.isDerivative)
    const isFuture = popoverNode.data().instrument_type === 'FUT'
    const elements =structuredClone(useSelector(state => state.graphTemplate.elementCopy));
    const [roles] = useGlobalState('roles');
    const [element , setElememt ] = useState ( deepCloneWithMutableProperties(elements.filter((element) => element.data.parent === popoverNode.data().properties.industry)))
    const clonedElement = []
    for(const item in element){
        const id = element[item].data.id
        const label = element[item].data.label
        clonedElement.push({data : {id : id, label : label, parent : element[item].data.parent, properties : element[item].data.properties}})
    }
    function deepCloneWithMutableProperties(obj) {
        if (obj === null || typeof obj !== 'object') {
          return obj;
        }
      
        if (Array.isArray(obj)) {
          return obj.map(deepCloneWithMutableProperties);
        }
      
        const clonedObj = {};
      
        for (const key of Reflect.ownKeys(obj)) {
          const descriptor = Object.getOwnPropertyDescriptor(obj, key);
      
          if (descriptor) {
            Object.defineProperty(clonedObj, key, {
              value: deepCloneWithMutableProperties(obj[key]),
              writable: true,
              enumerable: descriptor.enumerable,
              configurable: true
            });
          } else {
            clonedObj[key] = deepCloneWithMutableProperties(obj[key]);
          }
        }
      
        return clonedObj;
      }
      
    const imageStyle = {
        height : '25px',
        width : '20px',
        marginRight : '3%'
    }
    const listStyle = {
        position : 'absolute',
        left : popoverPosition.x ,
        top : popoverPosition.y,
        width : '12%'
    }
    const listItemStyle = {
        textDecoration : 'none', 
        color : 'black',
        display : 'flex',
        alignItems : 'center'
    }

    const showDerivative = () => {
      dispatch(setDerivativeIsIndex(isIndex));
      dispatch(setHasFetched(false));
      // sessionStorage.setItem(popoverNode?.data()?.properties?.name, JSON.stringify(popoverNode.json()));
      // sessionStorage.setItem(`${popoverNode?.data()?.properties?.name} Sibility`, JSON.stringify(elements.filter((element) => element.data.parent === popoverNode.data().properties.industry))); 
      
       //Popover node data 
       sessionStorage.setItem(popoverNode?.data()?.properties?.name, JSON.stringify(popoverNode.json()));

       //Popover node sector data
       sessionStorage.setItem(`${popoverNode?.data()?.parent} Sector`, JSON.stringify(elements.filter((element) => element.data.parent === popoverNode.data().properties.industry)));
       
       //Popover node parent data
       sessionStorage.setItem(popoverNode?.data()?.parent, JSON.stringify(popoverNode?.parent()?.data()));
       
       //Index data 
       sessionStorage.setItem(`${popoverNode?.data()?.properties?.name} Index`, JSON.stringify(popoverNode.parent().connectedEdges().map((node) => node.source().data())));
       
       //Index edges
       sessionStorage.setItem(`${popoverNode?.data()?.properties?.name} edge`,JSON.stringify(popoverNode.parent().connectedEdges().map((node) => node.data())))
    
      dispatch(setDerivativePopover(popoverNode))
      dispatch(setShowPopover(false));
    }

    const showChart =() => {
        dispatch(setShowPopover(false));
        dispatch(setShowChart(true));
        dispatch(setHasFetched(true))

    }
    const showForm = () => {
        dispatch(setShowPopover(false));
      dispatch(setHasFetched(true))
        dispatch(setShowForm(true));
    }
    const hideForm = (e) => {
      dispatch(setHasFetched(true))
      e.preventDefault();
      e.stopPropagation();
      dispatch(setShowPopover(false));
      e.preventDefault();
      e.stopPropagation();
        // dispatch(setShowForm(false));
        
    }
    return (
        <Popover
            showForm = {showForm}
            showChart = {showChart}
            showDerivative = {showDerivative}
            hideForm = {hideForm}
            listItemStyle ={listItemStyle}
            listStyle = {listStyle}
            isIndex = {isIndex}
            isSector = {isSector}
            isDerivative = {isDerivative}
            popoverNode = {popoverNode}
            imageStyle={imageStyle}
            isFuture = {isFuture}
            roles={roles}
        />
    )
}

export default PopoverTemplate;