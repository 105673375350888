import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalState } from "components/globalVariable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { setAccessToken } from "reducers/ProtectedRouteSlice";

const ProtectedRoute = ({ children }) => {
  const {
    isAuthenticated,
    error,
    isLoading,
    user,
    getAccessTokenSilently,
    logout,
    getIdTokenClaims,
  } = useAuth0();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [roles] = useGlobalState("roles");
  const navigate = useNavigate();

  // Check if it's an internal IP (Optimization: regex now supports varying segment lengths)
  const isInternalIP =
    /^http:\/\/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d+)?$/.test(
      window.location.origin
    );
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://service_api",
            scope:
              "openid profile email offline_access read:current_user update:current_user_metadata",
          },
          // cacheMode: "off",
        });
        dispatch(setAccessToken(accessToken));
        const idTokenClaims = await getIdTokenClaims();
        if (
          idTokenClaims?.login_time &&
          idTokenClaims?.login_time < Date.now()
        ) {
          localStorage.setItem("refresh_token_expiry", "");
          logout();
        } else
          localStorage.setItem(
            "refresh_token_expiry",
            idTokenClaims?.login_time
          );
      } catch (err) {
        if (err.error === "invalid_grant") {
          console.warn("Refresh token expired. Logging out...");
          logout({ returnTo: window.location.origin });
        } else if (err.error === "mfa_required") {
          console.warn("MFA required, prompting user...");
          navigate("/home");
        } else {
          console.error("Error fetching access token:", err);
        }
      }
    };

    if (isAuthenticated) {
      fetchAccessToken();
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    dispatch,
    getIdTokenClaims,
    logout,
    navigate,
  ]);

  // Internal IPs bypass authentication
  if (isInternalIP) return children;

  // Show loading state while authentication is in progress
  if (isLoading) return <div></div>;

  // Redirect unverified users to email verification page
  if (user && !user.email_verified) {
    return <Navigate to="/verifyEmail" />;
  }

  // Handle authentication errors
  if (error) return <div>Error: {error.message}</div>;

  // Role-based authentication for specific routes
  const roleBasedRoutes = {
    "/default/orders": ["TRADER"],
    "/default/trades": ["TRADER"],
    "/default/pnl": ["READ_PNL"],
    "/default/positions": ["READ_POSITION"],
    "default/greeks": ["TRADER", "READ_PNL", "READ_POSITION"],
    "/default/optionChain": ["TRADER", "READ_PNL", "READ_POSITION"],
  };

  const requiredRole = roleBasedRoutes[pathname];
  const isAuthorized = roles?.some((role) => requiredRole?.includes(role));
  if (requiredRole && (!isAuthenticated || !isAuthorized)) {
    return <Navigate to="/default/dashboard" />;
  }

  // Default authentication check
  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
