/* eslint-disable */
import "./Modal.css";
import { useEffect } from "react";
const Modal = ({ setOpen, isOpen, children, dispatch }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Escape") dispatch(setOpen(false));
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  return (
    <div
      style={{ minHeight: "100%" }}
      className="absolute flex justify-end items-center bg-black bg-opacity-80  w-full top-0 left-0 z-[200] pr-2 overflow-auto"
    >
      <div
        style={{ height: "100%", width: "50%" }}
        className={`${
          isOpen ? "modal-entry" : "modal-exit"
        } bg-white rounded-xl shadow-md p-2`}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
