/* eslint-disable */
import setGraphElements from "../presentation/CreateGraph";
import config from "config";
import { apiConfig } from "apiConfig";
import API_ENDPOINTS from "services/api";

const getData = async (fetchData, setElements, setGraphResult, dispatch) => {
  try {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const body = {
      host: config.DB_HOST,
      port: config.DB_PORT,
      user: config.DB_USER,
      password: config.DB_PASSWORD,
      database: config.DB_NAME,
      cmd:
        "SELECT * FROM cypher(" +
        config.GRAPH_NAME +
        ", $$ MATCH (n)OPTIONAL MATCH (n)-[r]->(m) return n, r $$) as (n agtype, r agtype);",
    };

    const response = await fetchData(
      API_ENDPOINTS.GET_ZEN_SECURITY_ANALYSER(),
      "POST",
      header,
      body
    );
    setElements(setGraphElements(response.rows));
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default getData;
