import { useDispatch, useSelector } from "react-redux";
import PnlChartContainerTemplate from "./PnlChartContainerTemplate";
const PnlChartContainer = () => {
  const showPnlChart = useSelector((state) => state.pnlChart.showPnlChart);
  const pnlChartData = useSelector((state) => state.pnlChart.pnlChartData);
  const closePnlChart = useSelector((state) => state.pnlChart.closePnlChart);
  const dispatch = useDispatch();
  return (
    <PnlChartContainerTemplate
      showPnlChart={showPnlChart}
      closePnlChart={closePnlChart}
      pnlChartData={pnlChartData}
      dispatch={dispatch}
    />
  );
};

export default PnlChartContainer;
