import Modal from "components/PnlGrid/presentation/Modal";
import PnlChartIndividual from "./PnlChartIndividual";
import { setClosePnlChart, setShowPnlChart } from "./PnlChartSlice";
const PnlChartContainerTemplate = ({
  showPnlChart,
  pnlChartData,
  closePnlChart,
  dispatch,
}) => {
  return (
    <>
      {showPnlChart && (
        <Modal
          setOpen={setShowPnlChart}
          setClose={setClosePnlChart}
          isOpen={showPnlChart}
          isClose={closePnlChart}
          dispatch={dispatch}
          children={
            <PnlChartIndividual
              showPnlChart={showPnlChart}
              pnlChartData={pnlChartData}
              dispatch={dispatch}
            />
          }
          data={pnlChartData}
        />
      )}
    </>
  );
};

export default PnlChartContainerTemplate;
