import { useRef } from "react";
import { useSelector } from "react-redux";
export const useFetchData = () => {
    let tokenPromise = null;
    const accessToken = useSelector(state => state.protectedRoute.accessToken);
    const tokenRef = useRef(accessToken); // Use ref to always get latest value

    tokenRef.current = accessToken;
    const waitForAccessToken = () =>
    {
        if(tokenRef.current)
            return Promise.resolve(tokenRef.current);

        if(!tokenPromise){
           tokenPromise = new Promise((resolve) => {
                const checkToken = () => {
                    if(tokenRef.current){
                        resolve(tokenRef.current);
                        tokenPromise = null;
                    }
                    else{
                        setTimeout(checkToken, 100);
                    }
                };
                checkToken();
            
           }) ;
        }

        return tokenPromise;
    }
   
    const fetchData = async (apiUrl, method = "GET", headers = {}, body = null) => {
        const token = await waitForAccessToken();
        if(token){
            headers["Authorization"] = `Bearer ${token}`;
        }
        try {
            const response = await fetch(apiUrl, {
                method,
                headers,
                body: body ? JSON.stringify(body) : null,
            });
    
            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }
    
            return await response.json(); // Return data
        } catch (error) {
            // console.error("Fetch error:", error);
            throw error; 
        }
    }
    return {fetchData, waitForAccessToken}
};
