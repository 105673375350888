/*eslint-disable*/
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import "./OptionChain.css";
import { useSelector } from "react-redux";
import CustomHeader from "./CustomHeader";

const OptionChain = ({
  gridApiRef,
  setGridApi,
  symbol,
  month,
  date,
  monthNames,
  sortOptions,
  setSortOptions,
  dispatch,
}) => {
  const columnOptions = useSelector(
    (state) => state.optionChainTemplate.columnOptions
  );
  const defaultColDef = {
    supressMovable: true,
  };

  const [columnDefs] = useState(columnOptions);

  const getRowId = (params) => params.data.key;

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);

    // gridApiRef.current.applyColumnState({ state: sortState });

    params.api.setFilterModel({
      underlying_zen_id: {
        filterType: "number",
        type: "equals",
        filter: symbol?.value,
      },
    });
    params.api.onFilterChanged();
  };

  // const onFirstDataRendered = (params) => {

  //   setTimeout(() => {
  //     const filterModel =
  //     {
  //       underlying_zen_id:
  //       {
  //             filterType: 'number',
  //             type: 'equals',
  //             filter: symbol?.value
  //       }
  //     };
  //     // if(date)
  //     // {
  //     //   filterModel['expiry_date'] =
  //     //   {
  //     //     filterType : 'date',
  //     //     type : 'equals',
  //     //     dateFrom : date,
  //     //     filterParams :
  //     //     {
  //     //       inRangeInclusive: true,
  //     //     }
  //     //   };
  //     // }
  //     // else if (month)
  //     // {
  //     //   if(todayDate.getMonth() === monthNames.indexOf(month))
  //     //   {
  //     //       startDate = new Date(todayDate).toISOString().split('T')[0];
  //     //       endDate = new Date(todayDate.getFullYear(),todayDate.getMonth() +1 , 1).toISOString().split('T')[0];
  //     //   }
  //     //   else
  //     //   {
  //     //       startDate = new Date(new Date(todayDate).getFullYear(),new Date(todayDate).getMonth()+1 , 2).toISOString().split('T')[0];
  //     //       endDate = new Date(new Date(todayDate).getFullYear(),new Date(todayDate).getMonth() +2 , 1).toISOString().split('T')[0];
  //     //   }
  //     //   filterModel['expiry_date'] =
  //     //   {
  //     //     filterType: 'date',
  //     //     type: 'inRange',
  //     //     dateFrom: startDate,
  //     //     dateTo:endDate,
  //     //     filterParams:
  //     //     {
  //     //       inRangeInclusive: true,
  //     //     }
  //     //   };
  //     // }

  //     params?.api?.setFilterModel(filterModel);
  //     params?.api?.onFilterChanged();
  //   }, 0);
  // };
  const onFirstDataRendered = (params) => {
    // Auto-size all columns based on content
    params.columnApi.autoSizeAllColumns({
      autoSizeStrategy: { type: "fitCellContents" },
    });

    // Ensure minWidth is enforced
    params.columnApi.getAllColumns().forEach((column) => {
      const colId = column.getColId();
      const colDef = column.getColDef();
      const minWidth = colDef.minWidth || 0;

      // Get the actual width after auto-sizing
      const actualWidth =
        params.columnApi.getColumnState().find((c) => c.colId === colId)
          ?.width || 0;

      // If auto-sized width is smaller than minWidth, set it to minWidth
      if (actualWidth < minWidth) {
        params.columnApi.setColumnWidth(colId, minWidth);
      }
    });
  };
  return (
    <div style={{ paddingLeft: "0px" }}>
      <div id="optionChainTableContainer">
        <div
          className="ag-theme-alpine"
          style={{ height: "83vh", width: "93vw" }}
        >
          <AgGridReact
            ref={gridApiRef}
            // rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            getRowId={getRowId}
            domLayout="normal"
            rowBuffer={10}
            suppressDragLeaveHidesColumns={true}
            onGridReady={onGridReady}
            // onFirstDataRendered={onFirstDataRendered}
            gridOptions={{ suppressSorting: true }}
            // onSortChanged={handleSortingData}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionChain;
