import { createSlice } from "@reduxjs/toolkit";
import UnrealizedPnlCellRenderer from "./UnrealizedPnlCellRenderer";
import { strategyLists } from "../presentation/PositionsGrid";

const positionGetter = (params) => {
  return params.value === undefined
    ? ""
    : Number(params.value).toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        manimumFractionDigits: 0,
      });
};
const costGetter = (params) => {
  return params.value === undefined
    ? ""
    : Math.round(Number(params.value)).toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
};
const avgCostGetter = (params) => {
  return params.value === undefined
    ? ""
    : Math.round(Number(params.value)).toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
};
const latestPriceGetter = (params) => {
  return params.value === undefined
    ? ""
    : Number(params.value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
};
const unRealizedPnlGetter = (params) => {
  return Math.round(Number(params.value)).toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};
const curMarketValueGetter = (params) => {
  return Math.round(Number(params.value)).toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

const betaValueFormatter = (params) => {
  return params.value === undefined ? "" : params.value?.toFixed(3);
};

const initialState = {
  filterText: "",
  columnOptions: [
    {
      headerName: "Date",
      field: "date",
      minWidth: 90,
      flex: 1,
      headerClass: "align-left dateHeader left-aligned-header-padding",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      sort: null,
    },
    {
      headerName: "Trading Symbol",
      field: "tradingSymbol",
      tooltipField: "Trading Symbol",
      headerClass: "custom-header-padding left-aligned-header-padding",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      sort: null,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Broker",
      field: "positionCompositeKey.broker",
      headerClass: "custom-header-padding left-aligned-header-padding",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      minWidth: 110,
      sort: null,
      flex: 1,
    },
    {
      headerName: "Strategy Name",
      field: "strategyLists",
      headerClass: "custom-header-padding left-aligned-header-padding",
      cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
      minWidth: 120,
      sort: null,
      valueGetter: (params) => {
        const strategyId = params?.data?.positionCompositeKey?.strategyId;
        return strategyId && strategyLists
          ? strategyLists.filter((str) => str.strategy_id === strategyId)[0]
              ?.strategy_name
          : "";
      },
      flex: 1,
    },

    {
      headerName: "Position",
      field: "position",
      valueFormatter: positionGetter,
      tooltipField: "Position",
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      minWidth: 95,
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      sort: null,
      flex: 1,
    },
    {
      headerName: "Open Cost",
      field: "openCost",
      valueFormatter: costGetter,
      headerClass:
        " custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      minWidth: 80,
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      sort: null,
      flex: 1,
    },

    {
      headerName: "Avg Cost Per Share",
      field: "averageCostPerShare",
      valueFormatter: avgCostGetter,
      headerClass:
        " custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      sort: null,
      minWidth: 120,
      flex: 1.5,
    },
    {
      headerName: "Latest Price",
      field: "latestPrice",
      valueFormatter: latestPriceGetter,
      headerClass:
        " custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 85,
      sort: null,
      flex: 1,
    },
    {
      headerName: "Unrealized Pnl",
      field: "unrealizedPnl",
      valueFormatter: unRealizedPnlGetter,
      headerClass:
        " custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      sort: null,
      cellRenderer: UnrealizedPnlCellRenderer,
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 120,
      cellRendererParams: {
        valueFormatter: unRealizedPnlGetter,
      },
      flex: 1,
    },
    {
      headerName: "Current Value",
      field: "curMarketValue",
      valueFormatter: curMarketValueGetter,
      headerClass:
        "custom-header-padding ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
      minWidth: 90,
      sort: null,
      flex: 1,
    },
    {
      headerName: "Beta",
      field: "beta",
      valueFormatter: betaValueFormatter,
      headerClass: " ag-right-aligned-header right-aligned-header-padding",
      cellStyle: { justifyContent: "end", padding: "0 20px 0 0" },
      minWidth: 110,
      sort: null,
      flex: 1,
    },
  ],
};

const PositionTableOptionSlice = createSlice({
  name: "positionTableOption",
  initialState,
  reducers: {
    setPositionTableOptionsData(state, action) {
      state.columnOptions = action.payload;
    },
    setFilterText(state, action) {
      state.filterText = /^[0-9]/.test(action.payload)
        ? action.payload.substring(1) // Remove the first character if it's a number
        : action.payload.replace(/[^a-zA-Z0-9]/g, ""); //Allow only number and alphabets
    },
  },
});

export const { setPositionTableOptionsData, setFilterText } =
  PositionTableOptionSlice.actions;
export default PositionTableOptionSlice.reducer;
