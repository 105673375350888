/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { strategyLists } from "../presentation/TradesGrid";

const orderExecutionTimeGetter = (params) => {
  return params.value.replace("T", " ").slice(0, 19);
};

const priceGetter = (params) => {
  return Number(params.value).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
const quantityGetter = (params) => {
  return Number(params.value).toLocaleString("en-IN", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

const TradeTypeCellRenderer = (props) => {
  if (props.value === "BUY") {
    return (
      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        {props.value}
      </span>
    );
  }
  return (
    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
      {props.value}
    </span>
  );
};

const initialState = {
  columnOptions: [
    {
      headerName: "Trading Symbol",
      headerClass: "custom-header-padding left-aligned-header-padding",
      field: "tradingSymbol",
      minWidth: 130,
      cellClass: "left-aligned-cell-padding",
      sort: null,
      flex: 1,
    },
    {
      headerName: "Broker",
      headerClass: "custom-header-padding left-aligned-header-padding",
      field: "positionCompositeKey.broker",
      tooltipField: "broker",
      width: 130,
      cellClass: "left-aligned-cell-padding",
      minWidth: 110,
      sort: null,
      flex: 1,
    },
    {
      headerName: "Strategy Name",
      field: "strategyLists",
      headerClass: "custom-header-padding left-aligned-header-padding",
      cellClass: "left-aligned-cell-padding",
      minWidth: 120,
      sort: null,
      valueGetter: (params) => {
        const strategyId = params?.data?.positionCompositeKey?.strategyId;
        return strategyId && strategyLists
          ? strategyLists.filter((str) => str.strategy_id === strategyId)[0]
              ?.strategy_name
          : "";
      },
      flex: 1,
    },
    {
      headerName: "Exchange",
      headerClass: "custom-header-padding left-aligned-header-padding",
      field: "exchange",
      width: 120,
      cellClass: "left-aligned-cell-padding",
      sort: null,
    },
    {
      headerName: "Type",
      field: "transactionType",
      headerClass: "custom-header-padding left-aligned-header-padding",
      cellClass: "left-aligned-cell-padding",
      width: 85,
      sort: null,
      cellRenderer: TradeTypeCellRenderer,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      valueFormatter: quantityGetter,
      headerClass:
        "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 10px 0 0",
      },
      minWidth: 110,
      sort: null,
      flex: 0.5,
    },
    {
      headerName: "Price",
      field: "price",
      tooltipField: "price",
      valueFormatter: priceGetter,
      headerClass:
        "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 10px 0 0",
      },
      minWidth: 75,
      sort: null,
      flex: 1,
    },
    {
      headerName: "Order Execution Time",
      field: "orderExecutionTime",
      valueFormatter: orderExecutionTimeGetter,
      minWidth: 180,
      width: 230,
      headerClass: "ag-right-aligned-header",
      headerStyle: { padding: "0 25px 0 0" },
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 25px 0 0",
      },
      // flex: 1,
      sort: null,
    },
  ],
};

const TradesTableOptionSlice = createSlice({
  name: "tradesTableOption",
  initialState,
  reducers: {
    setTradestableOptionsData(state, action) {
      state.columnOptions = action.payload;
    },
  },
});

export const { setTradestableOptionsData } = TradesTableOptionSlice.actions;
export default TradesTableOptionSlice.reducer;
