/*eslint-disable*/
import { Toast, ToastContainer, Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import {
  setShowOrderResponse,
  setOrderMessage,
  setOrderStatus,
} from "components/OrderForm/Field/orderFormSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Toasts.css";
import { useEffect } from "react";
import { useGlobalState } from "components/globalVariable";
import { useLocation } from "react-router-dom";

const Toasts = () => {
  const [clientId] = useGlobalState("clientId");
  const location = useLocation();
  const dispatch = useDispatch();
  const orderMessage = useSelector((state) => state.orderForm.orderMessage);
  const orderStatus = useSelector((state) => state.orderForm.orderStatus);
  const showOrderResponse = useSelector(
    (state) => state.orderForm.showOrderResponse
  );
  useEffect(() => {
    dispatch(setShowOrderResponse(false));
    return undefined;
  }, [clientId, location]);

  return (
    <ToastContainer
      position="bottom-center"
      className="p-3"
      style={{
        position: "relative",
        left: "75%",
        zIndex: 1000000000000,
        width: "500px",
      }}
    >
      {showOrderResponse && (
        <Toast>
          <Toast.Header
            style={{
              backgroundColor:
                orderStatus != "ACCEPTED" ? "#ff2424" : "#088761",
              color: "white",
              height: "2.5rem",
            }}
          >
            <strong className="me-auto ">
              {orderMessage !== "" ? orderStatus : "Success"}
            </strong>
            <Button
              variant="link"
              onClick={() => {
                dispatch(setShowOrderResponse(false));
                dispatch(setOrderMessage(null));
                dispatch(setOrderStatus(null));
              }}
              className="ms-auto text-decoration-none"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "white")}
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
            >
              <IoClose />
            </Button>
          </Toast.Header>
          <Toast.Body>
            {orderMessage !== "" ? orderMessage : orderStatus}
          </Toast.Body>
        </Toast>
      )}
    </ToastContainer>
  );
};

export default Toasts;
