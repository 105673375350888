/* eslint-disable */
import React, { useState } from 'react';
import { createGlobalState } from "react-hooks-global-state";
import Cookies from "universal-cookie";


const oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);


export const cookies = new Cookies(); 

const { setGlobalState, useGlobalState, subscribe } = createGlobalState({
    clientId:cookies.get('clientId') , clientName : cookies.get('clientName'), accountIds : cookies.get('accountIds'), brokerName : cookies.get("brokerName"), roles : cookies.get("roles")
},);

export { useGlobalState, setGlobalState, subscribe };