/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  windowSize: {
    width: window.innerWidth,
    height: window.innerHeight
  },
  showChart : false,
  showDerivative : false,
  showForm : false,
  equity : null,
  sector : null,
  targetNode : '',
  graphResult : '',
  popoverNode : [],
  isGraph : false,
  elementCopy : [],
  elements : [],
  equityValues : [],
  backgroundColor : 'transparant',
  isReseting : false
};

const GraphTemplateSlice = createSlice({
  name: 'graphTemplate',
  initialState,
  reducers: {
    setWindowSize(state, action){
        state.windowSize = action.payload
    },
    setShowChart(state, action){
        state.showChart = action.payload
    },
    setShowDerivative(state, action){
        state.showDerivative = action.payload
    },
    setShowForm(state, action){
      state.showForm = action.payload
    },
    setShowGraph(state, action){
      state.showGraph = action.payload
    },
    setEquity(state, action){
        state.equity = action.payload
    },
    setSector(state, action){
        state.sector = action.payload
    },
    setGraphResult(state, action){
      state.graphResult = action.payload
    },
    setPopoverNode(state, action){
      state.popoverNode = action.payload
    },
    setTargetNode(state, action){
      state.targetNode = action.payload
    },
    setIsGraph(state, action){
      state.isGraph = action.payload
    },
    setElementCopy(state, action){
      state.elementCopy = action.payload
    },
    setElements(state, action){
      state.elements = action.payload
    },
    setEquityValues(state, action){
      state.equityValues = action.payload
    },
    setBackgroundColor(state, action){
      state.backgroundColor = action.payload
    },
    setIsReseting(state, action){
      state.isReseting = action.payload
    }

  }
});

export const { setWindowSize , setShowChart, setShowDerivative, setShowForm, setShowGraph, 
  setEquity, setSector, setTargetNode, setGraphResult, setPopoverNode, setIsGraph, 
  setElementCopy , setElements, setEquityValues, setBackgroundColor, setIsReseting} = GraphTemplateSlice.actions;
export default GraphTemplateSlice.reducer;
