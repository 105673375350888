/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";
import ButtonRenderer from "./ButtonRenderer";
import CustomHeader from "./CustomHeader";

const cellFirstRules = {
  "yellow-cell": (params) =>
    params.data &&
    params.data.moneyness_call !== "OTM" &&
    params.data.moneyness_call != "-",
};

const cellLastRules = {
  "yellow-cell": (params) => {
    return (
      params.data &&
      params.data.moneyness_put !== "OTM" &&
      params.data.moneyness_put !== "-"
    );
  },
};

const callFormatterForGamma = (params) => {
  return isNaN(params.value)
    ? params.value
    : (Number(params.value) * 100).toFixed(4);
};

const callFormatter = (params) => {
  return isNaN(params.value) ? params.value : Number(params.value).toFixed(2);
};

// const moneynessComparator = (a, b) =>
// {
//   const order = { 'ITM': 1, 'ATM': 2, 'OTM': 3 };
//   return order[a] - order[b];
// };

const priceGetter = (params) => {
  return Number(params.value).toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
};

const initialState = {
  showOrderForm: false,
  tradingSymbol: "",
  callType: "BUY",
  month: "ALL",
  date: "ALL",
  dateOptions: [
    "2024-08-16",
    "2024-08-19",
    "2024-09-11",
    "2024-09-16",
    "2024-10-16",
    "2024-10-12",
  ],
  dateList: [],
  monthList: [],
  symbol: { label: "NIFTY", value: 37761 },
  symbolName: "NIFTY",
  niftyDateOptions: [],
  bankNiftyDateOptions: [],
  niftyStrikeOptions: [],
  bankNiftyStrikeOptions: [],
  strike: "ALL",
  isMarketOpen: true,
  strikeList: [],
  filterModel: {},
  isFetchingFirst: true,
  isFetching: true,
  niftyEodPrice: "",
  bankNiftyEodPrice: "",
  expiryList: [],
  columnOptions: [
    {
      headerName: "CALLS",
      // headerClass :'header-put',
      // headerClass :'header-put',
      children: [
        {
          headerName: "Gamma",
          field: "gamma-call",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 100,
          valueFormatter: (params) => callFormatterForGamma(params),
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            value: params.value,
            tradingSymbol: params.data.key + "CE",
          }),
          cellClassRules: cellFirstRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[0]
        },
        {
          headerName: "Vega",
          field: "vega-call",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 80,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellFirstRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[1]
        },
        {
          headerName: "Theta",
          field: "theta-call",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 80,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellFirstRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[2]
        },
        {
          headerName: "Delta",
          field: "delta-call",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 80,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellFirstRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[3]
        },
        {
          headerName: "LTP",
          field: "ltp-call",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 70,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellFirstRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[4]
        },
      ],
    },
    {
      headerName: "Trading Symbol",
      field: "key",
      minWidth: 150,
      headerClass: "ag-left-aligned-header",
      hide: true,
      sort: null,
      flex: 1.5,
      cellStyle: { padding: "0" },
      // sort : sortOptions[5]
    },
    {
      headerName: "Strike",
      field: "strike",
      minWidth: 80,
      filter: true,
      headerClass: "ag-right-aligned-header",
      cellStyle: { justifyContent: "right", padding: "0 10px 0 0 " },
      valueFormatter: (params) => priceGetter(params),
      // sortable : true,
      flex: 1,
      sort: "asc",
      headerComponent: CustomHeader,
    },
    {
      headerName: "Underlying Zen id",
      field: "underlying_zen_id",
      headerClass: "ag-right-aligned-header",
      width: 100,
      hide: true,
      filter: true,
      flex: 1,
      sort: null,
      cellStyle: { padding: "0 10px 0 0" },
      // sort : sortOptions[7]
    },
    {
      headerName: "Expiry",
      field: "expiry_date",
      width: 120,
      filter: true,
      // filter : MonthFilter
      cellStyle: { padding: "0 0 0 10px" },
      filterParams: {
        inRangeInclusive: true,
      },
      headerComponent: CustomHeader,
      comparator: (date1, date2) => {
        return new Date(date1) - new Date(date2); // Ascending order
      },
      sortable: true,
      sort: "asc",
      // sort : sortOptions[8]
    },
    // {
    //   headerName: 'Moneyness call',
    //   field: 'moneyness_call' ,
    //   headerClass: 'header-blue',
    //   width:150,
    //   comparator: moneynessComparator ,
    //   hide: false,
    //   sort : "asc",
    //   // sort : sortOptions[9]
    // },
    // {
    //   headerName: 'Moneyness put',
    //   field: 'moneyness_put' ,
    //   headerClass: 'header-blue',
    //   width:150,
    //   // comparator: moneynessComparator ,
    //   hide: false,
    //   sort : null,
    //   // sort : sortOptions[9]
    // },
    {
      headerName: "PUTS",
      // headerClass :'header-call',
      // headerClass :'header-call',
      width: 700,
      children: [
        {
          headerName: "LTP",
          field: "ltp-put",
          headerClass: "header-white ag-right-aligned-header",
          width: 100,
          // cellRenderer: ButtonRenderer,
          cellClassRules: cellLastRules,
          valueFormatter: (params) => callFormatter(params),
          // cellRendererParams: (params)=> (
          //   {
          //     value : params.value,
          //     tradingSymbol : params.data.key+"PE",
          //   }
          // ),
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[10]
        },
        {
          headerName: "Delta",
          field: "delta-put",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 80,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellLastRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
        },
        {
          headerName: "Theta",
          field: "theta-put",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 80,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellLastRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[12]
        },
        {
          headerName: "Vega",
          field: "vega-put",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 80,
          valueFormatter: (params) => callFormatter(params),
          cellClassRules: cellLastRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[13]
        },
        {
          headerName: "Gamma",
          field: "gamma-put",
          headerClass: "header-white ag-right-aligned-header",
          minWidth: 100,
          valueFormatter: (params) => callFormatterForGamma(params),
          cellRenderer: ButtonRenderer,
          cellRendererParams: (params) => ({
            value: params.value,
            tradingSymbol: params.data.key + "PE",
          }),
          cellClassRules: cellLastRules,
          cellStyle: { justifyContent: "right", padding: "0 10px 0 0" },
          flex: 1,
          sort: null,
          // sort : sortOptions[14]
        },
      ],
    },
  ],
  sortOptions: [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "asc",
    null,
    null,
    null,
    null,
    null,
  ],
};

const OptionChainSlice = createSlice({
  name: "optionChainTemplate",
  initialState,
  reducers: {
    setShowOrderForm(state, action) {
      state.showOrderForm = action.payload;
    },
    setTradingSymbol(state, action) {
      state.tradingSymbol = action.payload;
    },
    setCallType(state, action) {
      state.callType = action.payload;
    },
    setMonth(state, action) {
      state.month = action.payload;
    },
    setDateList(state, action) {
      state.dateList = action.payload;
    },
    setMonthList(state, action) {
      state.monthList = action.payload;
    },
    setDate(state, action) {
      state.date = action.payload;
    },
    setSymbol(state, action) {
      state.symbol = action.payload;
    },
    setNiftyDateOptions(state, action) {
      state.niftyDateOptions = action.payload;
    },
    setBankNiftyDateOptions(state, action) {
      state.bankNiftyDateOptions = action.payload;
    },
    setSymbolName(state, action) {
      state.symbolName = action.payload;
    },
    setFilterModel(state, action) {
      state.filterModel = action.payload;
    },
    setIsFetchingFirst(state, action) {
      state.isFetchingFirst = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSortOptions(state, action) {
      state.sortOptions = action.payload;
    },
    setColumnOptions(state, action) {
      state.columnOptions = action.payload;
    },
    setNiftyStrikeOptions(state, action) {
      state.niftyStrikeOptions = action.payload;
    },
    setBankNiftyStrikeOptions(state, action) {
      state.bankNiftyStrikeOptions = action.payload;
    },
    setStrikeList(state, action) {
      state.strikeList = action.payload;
    },
    setStrike(state, action) {
      state.strike = action.payload;
    },
    setNiftyEodPrice(state, action) {
      state.niftyEodPrice = action.payload;
    },
    setBankNiftyEodPrice(state, action) {
      state.bankNiftyEodPrice = action.payload;
    },
    setIsMarketOpen(state, action) {
      state.isMarketOpen = action.payload;
    },
    setExpiryList(state, action) {
      state.expiryList = action.payload;
    },
  },
});

export const {
  setShowOrderForm,
  setTradingSymbol,
  setCallType,
  setMonth,
  setDateList,
  setMonthList,
  setDate,
  setSymbol,
  setNiftyDateOptions,
  setBankNiftyDateOptions,
  setSymbolName,
  setFilterModel,
  setIsFetchingFirst,
  setIsFetching,
  setSortOptions,
  setColumnOptions,
  setBankNiftyStrikeOptions,
  setNiftyStrikeOptions,
  setStrikeList,
  setStrike,
  setNiftyEodPrice,
  setBankNiftyEodPrice,
  setIsMarketOpen,
  setExpiryList,
} = OptionChainSlice.actions;
export default OptionChainSlice.reducer;
