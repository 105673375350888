/*eslint-disable*/
import { useGlobalState } from "components/globalVariable"
import Margin from "./Margin"
import { useEffect } from "react"
import getAvailableMargin from "./cypherResult"
import { setAvailableMargin } from "./MarginTemplateSlice"
import { useDispatch, useSelector } from "react-redux"
import { useFetchData } from "utils/customFetch"

const MarginTemplate = () => {
    const [clientId] = useGlobalState("clientId");
    const { fetchData } = useFetchData();
    const availableMargin = useSelector(state => state.marginTemplate.availableMargin)
    const dispatch = useDispatch()
    const priceGetter = (params) => { 
        if(isNaN(params))return "N/A"
        return Math.round(Number(params)).toLocaleString('en-IN');
    }
    const {selectedAccount, selectedBroker} = useSelector(state => state.clientSelection);

    useEffect(() => {
        if(clientId && selectedBroker && selectedAccount && selectedAccount!=='all')
        getAvailableMargin(fetchData, clientId,selectedAccount, dispatch, setAvailableMargin)
    },[clientId, availableMargin, selectedAccount])

    return( 
    <Margin
        availableMargin = {availableMargin}
        priceGetter = {priceGetter}
    />)
}

export default MarginTemplate;