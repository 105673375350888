/*eslint-disable*/
import React, { useMemo, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import "ag-grid-community/styles//ag-theme-balham.css";
import "ag-grid-community/styles//ag-theme-material.css";

import { useState, useEffect } from "react";
import { useGlobalState } from "components/globalVariable";
import { useSelector, useDispatch } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import {
  setTableOptionsData,
  setFilterText,
} from "../container/PnlTableOptionSlice";
import TableRefresher from "components/TableRefreshComponet/TableRefresher";
import { Card } from "reactstrap";
import { apiConfig } from "apiConfig";
import { useFetchData } from "utils/customFetch";
import API_ENDPOINTS from "services/api";
import Modal from "./Modal";
import { setPnlChartData } from "components/PnlChart/PnlChartSlice";
import { setShowPnlChart } from "components/PnlChart/PnlChartSlice";
let strategyLists;
const PnlGrid = () => {
  const { fetchData } = useFetchData();
  const [count, setCount] = useState(1);
  const [refreshTime, setRefreshTime] = useState(
    new Date().toLocaleTimeString([], { hour12: true }).replace("pm", "PM")
  );
  const { dates, broker, security, strategy, zenSecIds } = useSelector(
    (state) => state.filter
  );
  const {
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
    selectedAccount,
  } = useSelector((state) => state.clientSelection);
  const { columnOptions } = useSelector((state) => state.pnlTableOption);
  const dispatch = useDispatch();
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const [clientName] = useGlobalState("clientName");
  const [rowData, setRowData] = useState();
  const filterText = useSelector((state) => state.pnlTableOption.filterText);
  strategyLists = useSelector((state) => state.filter.strategyList);

  const gridRef = useRef(null);
  const onGridReady = (params) => {
    if (params.api) {
      gridRef.current = params.api; // Store the API reference
      calculateSum();
    }
  };

  // const getRowHeight = useCallback(() => {
  //   const screenWidth = window.innerWidth; // Get screen width

  //   if (screenWidth < 768) {
  //     return 18; // Small screens (mobile)
  //   } else if (screenWidth < 1024) {
  //     return 35; // Medium screens (tablets)
  //   } else {
  //     return 41; // Large screens (desktops)
  //   }
  // }, []);

  const getFilteredRows = () => {
    if (gridRef.current) {
      const filteredRows = [];
      gridRef.current.forEachNode((node) => {
        if (node?.rowIndex !== null) {
          // Only include visible rows
          filteredRows?.push(node.data);
        }
      });
      return filteredRows;
    }
    return [];
  };
  const dateConversion = (val) => {
    const dateObj = new Date(val);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const cellClickedListener = () => {
    setCount(count + 1);
  };
  const [topRowData, setTopRowData] = useState({
    realizedLTDPnl: 0,
    unRealizedLTDPnl: 0,
    realizedDTDPnl: 0,
    unRealizedDTDPnl: 0,
    realizedMTDPnl: 0,
    unRealizedMTDPnl: 0,
    realizedYTDPnl: 0,
    unRealizedYTDPnl: 0,
    dividend: 0,
  });

  /*
        Total Unrealized PnL = Unrealized PnL from the latest date in the range.  
        Total Realized PnL = Sum of the Realized PnL values across all dates in the range.
    */

  function calculateSum() {
    let latestDate = dateConversion(dates[1]);
    let latestPnl, fullData;
    if (filterText !== "") {
      latestPnl = getFilteredRows()?.filter((row) => row?.date === latestDate);
      fullData = getFilteredRows();
    } else {
      latestPnl = rowData?.filter((row) => row?.date === latestDate);
      fullData = rowData;
    }
    if (rowData) {
      let realizedLTDPnlsum = 0;
      let unRealizedLTDPnlsum = 0;
      let realizedDTDPnlsum = 0;
      let unRealizedDTDPnlsum = 0;
      let realizedMTDPnlsum = 0;
      let unRealizedMTDPnlsum = 0;
      let realizedYTDPnlsum = 0;
      let unRealizedYTDPnlsum = 0;
      let dividendSum = 0;
      latestPnl?.forEach((row) => {
        unRealizedLTDPnlsum += Math.round(row?.unRealizedLTDPnl);
        unRealizedDTDPnlsum += Math.round(row?.unRealizedDTDPnl);
        unRealizedMTDPnlsum += Math.round(row?.unRealizedMTDPnl);
        unRealizedYTDPnlsum += Math.round(row?.unRealizedYTDPnl);
      });
      fullData?.forEach((row) => {
        realizedLTDPnlsum += Math.round(row?.realizedLTDPnl);
        realizedDTDPnlsum += Math.round(row?.realizedDTDPnl);
        realizedMTDPnlsum += Math.round(row?.realizedMTDPnl);
        realizedYTDPnlsum += Math.round(row?.realizedYTDPnl);
        dividendSum += Math.round(row?.dividend);
      });
      setTopRowData({
        realizedLTDPnl: realizedLTDPnlsum,
        unRealizedLTDPnl: unRealizedLTDPnlsum,
        realizedDTDPnl: realizedDTDPnlsum,
        unRealizedDTDPnl: unRealizedDTDPnlsum,
        realizedMTDPnl: realizedMTDPnlsum,
        unRealizedMTDPnl: unRealizedMTDPnlsum,
        realizedYTDPnl: realizedYTDPnlsum,
        unRealizedYTDPnl: unRealizedYTDPnlsum,
        dividend: dividendSum,
      });
    }
  }
  useEffect(() => {
    calculateSum();
  }, [rowData, filterText]);

  const pinnedTopRowData = useMemo(() => {
    return [topRowData];
  }, [topRowData]);

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,

    headerClass: { textAlign: "right" },
    cellStyle: (params) => {
      if (params.value < 0) {
        return {
          color: "red",
          display: "flex",
          justifyContent: "flex-end",
        };
      } else {
        return {
          display: "flex",
          justifyContent: "flex-end",
        };
      }
    },
  };

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return { background: "rgb(243, 238, 238)", cursor: "pointer" };
    }
    return { cursor: "pointer" };
  };
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    setIsFetching(true);
    const fetchDataCall = async () => {
      const body = {
        ...(dates[0].toLocaleDateString() !== new Date().toLocaleDateString()
          ? {
              startTimestamp: `${dateConversion(dates[0])} 00:00:00`,
              endTimestamp: `${dateConversion(dates[1])} 23:59:59`,
            }
          : {}),
        positionCompKeyFilter: {
          clientId: clientId,
          ...(zenSecIds === null || zenSecIds?.length === 0
            ? {}
            : { zenSecIds: zenSecIds }),
          ...(selectedAccount === "all" ||
          selectedAccount === "" ||
          selectedAccount === null ||
          selectedAccount === undefined
            ? {}
            : { accountIds: [selectedAccount] }),
          // Conditionally include strategyIds only if strategy is not empty
          ...(selectedStrategy === "all" ||
          selectedStrategy === "" ||
          selectedStrategy === null ||
          selectedStrategy === undefined
            ? {}
            : { strategyIds: [selectedStrategy] }),
          ...(selectedBroker === "all" ||
          selectedBroker === "" ||
          selectedBroker === null ||
          selectedBroker === undefined
            ? {}
            : { brokers: [selectedBrokerName] }), // Only include brokers if broker is not "ALL"
        },
      };
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      try {
        const rowData = await fetchData(
          API_ENDPOINTS.GET_PNL(),
          "POST",
          header,
          body
        );
        setRowData(Array.from(rowData));
        setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
        setTimeout(() => setIsFetching(false), 750);
      } catch (error) {
        console.log("render error: ", error);
      }
    };
    fetchDataCall();
    const intervalId = setInterval(fetchDataCall, 120000);

    //setIntervel cleaning process
    return () => {
      clearInterval(intervalId);
    };
  }, [
    zenSecIds,
    dates,
    count,
    clientId,
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
    selectedAccount,
  ]);

  const containerStyle = useMemo(
    () => ({ height: "auto", width: "auto", overflow: "hidden" }),
    []
  );
  const gridStyle = useMemo(
    () => ({
      height: "75vh",
      width: "93vw",
    }),
    []
  );
  const filterStyle = useMemo(() => ({ height: "50px", width: "100%" }), []);
  const tableStyle = useMemo(
    () => ({
      height: "100%",
      width: "100%",
      margin: "15px",
      position: "relative",
      border: "2px solid black",
    }),
    []
  );
  const tdStyle = useMemo(() => ({ width: "50%" }), []);
  const halfStyle = useMemo(
    () => ({ width: "500px", "padding-left": "15px" }),
    []
  );

  const [open, setOpen] = React.useState(false);
  const groupIncludeTotalFooter = true;
  const [preVal, setPreVal] = useState("");

  //gettinging current table options from redux and updating it
  //then dispatching
  const optionsUpdater = (stateList) => {
    const newdata = columnOptions.map((option, i) => {
      return { ...option, sort: stateList[i] };
    });
    dispatch(setTableOptionsData(newdata));
  };
  const handleSortingData = (e) => {
    const state = e.columnApi?.getColumnState();
    const sortState = state?.map((item) => item.sort);
    optionsUpdater(sortState);
  };

  const onFirstDataRendered = (params) => {
    // Auto-size all columns based on content
    params.columnApi.autoSizeAllColumns({
      autoSizeStrategy: { type: "fitCellContents" },
    });

    // Ensure minWidth is enforced
    params.columnApi.getAllColumns().forEach((column) => {
      const colId = column.getColId();
      const colDef = column.getColDef();
      const minWidth = colDef.minWidth || 0;

      // Get the actual width after auto-sizing
      const actualWidth =
        params.columnApi.getColumnState().find((c) => c.colId === colId)
          ?.width || 0;

      // If auto-sized width is smaller than minWidth, set it to minWidth
      if (actualWidth < minWidth) {
        params.columnApi.setColumnWidth(colId, minWidth);
      }
    });
  };

  // const handleTableScroll = (event) => {
  //   const gridBodyElement = gridRef?.current
  //     ?.getGui()
  //     ?.querySelector(".ag-body-viewport");
  //   if (!gridBodyElement || !gridBodyElement.contains(event.target)) return;

  //   const { scrollTop, scrollHeight, clientHeight } = gridBodyElement;
  //   if (scrollHeight > clientHeight) {
  //     event.stopPropagation(); // Stop scroll from reaching outer container
  //   }
  //   // Prevent scrolling outside if at the top or bottom
  //   if (
  //     (scrollTop === 0 && event.deltaY < 0) || // At the top and scrolling up
  //     (scrollTop + clientHeight === scrollHeight && event.deltaY > 0) // At the bottom and scrolling down
  //   ) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  // };

  // useEffect(() => {
  //   const gridBodyElement = gridRef?.current
  //     ?.getGui()
  //     ?.querySelector(".ag-body-viewport");
  //   if (!gridBodyElement) return;

  //   gridBodyElement.addEventListener("wheel", handleTableScroll, {
  //     passive: false,
  //   });

  //   return () => {
  //     gridBodyElement.removeEventListener("wheel", handleTableScroll);
  //   };
  // }, []);
  return (
    <>
      <Card>
        <div style={gridStyle} className="ag-theme-alpine">
          <header
            className="flex items-center p-2 justify-between"
            style={{ backgroundColor: "#e5ebf2" }}
          >
            <span className="text-sm font-semibold font-sans">Pnl</span>
            <div className="flex items-center">
              <div className="relative flex items-center">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400 absolute left-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <input
                  type="search"
                  className="bg-slate-50 font-semibold rounded-md pl-8 pr-2 py-2 border text-gray-500 focus:ring-2"
                  placeholder="Search"
                  value={filterText}
                  onChange={(e) => dispatch(setFilterText(e.target.value))}
                />
              </div>
              <div className="pl-1">
                <TableRefresher
                  cellClickedListener={cellClickedListener}
                  showSpinner={isFetching}
                />
              </div>
            </div>
          </header>
          {isFetching ? (
            <ShimmerTable row={6} col={6} />
          ) : (
            <>
              {/* <Modal /> */}
              <AgGridReact
                localeText={{
                  noRowsToShow:
                    "No PnL data available yet. Execute trades to see your performance!",
                }}
                columnDefs={[...columnOptions]}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                filterStyle={filterStyle}
                ref={gridRef}
                rowData={rowData}
                pinnedTopRowData={pinnedTopRowData}
                getRowStyle={getRowStyle}
                onSortChanged={handleSortingData}
                // onFirstDataRendered={onFirstDataRendered}
                quickFilterText={filterText}
                suppressDragLeaveHidesColumns={true}
                // getRowHeight={getRowHeight}
                rowHeight={41}
                domLayout="normal"
                rowSelection="single"
                onRowClicked={(event) => {
                  dispatch(setPnlChartData(event.data));
                  dispatch(setShowPnlChart(true));
                }}
                setGridApi={(api) => {
                  gridRef.current = api;
                }}
              ></AgGridReact>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default PnlGrid;
export { strategyLists };
