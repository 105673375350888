import { useAuth0 } from "@auth0/auth0-react";

const useIdleCheck = () => {
  const MFA_TIMEOUT = 2 * 60 * 60 * 1000;
  const { loginWithRedirect, logout } = useAuth0();

  const triggerMFA = () => {
    const refreshTokenExpiry = localStorage.getItem("refresh_token_expiry");
    const now = Date.now();
    if (refreshTokenExpiry < now) {
      logout();
    } else {
      loginWithRedirect({
        authorizationParams: {
          acr_values:
            "http://schemas.openid.net/pape/policies/2007/06/multi-factor",
        },
      });
    }
  };

  // Reset timer on activity
  const resetTimer = (timeoutRef) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(triggerMFA, MFA_TIMEOUT);
  };

  return { resetTimer };
};

export default useIdleCheck;
