/*eslint-disable*/
import { AgGridReact } from "ag-grid-react";
import TableRefresher from "components/TableRefreshComponet/TableRefresher";
import { useGlobalState } from "components/globalVariable";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import { useRef } from "react";
import { apiConfig } from "apiConfig";
import { useFetchData } from "../../utils/customFetch";
import API_ENDPOINTS from "services/api";
import "../../styles/agGridStyles.css";

function GreeksGrid() {
  const { fetchData } = useFetchData();
  const { dates, broker, security, exchange, portfolio, entity, strategy } =
    useSelector((state) => state.filter);
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const [clientName] = useGlobalState("clientName");
  const [rowData, setRowData] = useState();
  const gridApiRef = useRef(null);
  const [count, setCount] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const cellClickedListener = () => {
    setCount(count + 1);
  };

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return { background: "rgb(243, 238, 238)" };
    }
  };

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,

    headerClass: { textAlign: "right" },
    cellStyle: (params) => {
      if (params.value < 0) {
        return { color: "red", display: "flex", justifyContent: "flex-end" };
      } else {
        return { display: "flex", justifyContent: "flex-end" };
      }
    },
  };

  // const gridStyle = useMemo(() => ({ height: "510px", width: "1323px" }), []);
  const gridStyle = useMemo(() => ({ height: "75vh", width: "93vw" }), []);
  const filterStyle = useMemo(() => ({ height: "50px", width: "100%" }), []);

  const valueFormatter = (params) => {
    const formattedValue = params.value.toLocaleString("en-IN", {
      maximumFractionDigits: 4,
      minimumFractionDigits: 4,
    });
    return formattedValue;
  };

  const priceGetter = (params) => {
    return Number(params.value).toLocaleString("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
  };

  const callFormatterForLtp = (params) => {
    return isNaN(params.value) ? params.value : Number(params.value).toFixed(2);
  };

  const onFirstDataRendered = (params) => {
    // Auto-size all columns based on content
    params.columnApi.autoSizeAllColumns({
      autoSizeStrategy: { type: "fitCellContents" },
    });

    // Ensure minWidth is enforced
    params.columnApi.getAllColumns().forEach((column) => {
      const colId = column.getColId();
      const colDef = column.getColDef();
      const minWidth = colDef.minWidth || 0;

      // Get the actual width after auto-sizing
      const actualWidth =
        params.columnApi.getColumnState().find((c) => c.colId === colId)
          ?.width || 0;

      // If auto-sized width is smaller than minWidth, set it to minWidth
      if (actualWidth < minWidth) {
        params.columnApi.setColumnWidth(colId, minWidth);
      }
    });
  };

  const columnOptions = useMemo(
    () => [
      {
        headerName: "Trading Symbol",
        field: "trading_symbol",
        headerClass:
          "ag-left-aligned-header custom-header-padding left-aligned-header-padding",
        cellClass: "align-left",
        cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
        minWidth: 150,
        flex: 1.5,
        sort: null,
      },
      {
        headerName: "Last Price",
        field: "last_price",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
        minWidth: 80,
        valueFormatter: (params) => callFormatterForLtp(params),
        flex: 1,
        sort: null,
      },
      {
        headerName: "Strike",
        field: "strike",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
        valueFormatter: (params) => priceGetter(params),
        minWidth: 80,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Expiry",
        field: "expiry",
        headerClass:
          "ag-left-aligned-header custom-header-padding left-aligned-header-padding",
        cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
        width: 120,
        sort: null,
      },
      {
        headerName: "Moneyness",
        field: "moneyness",
        headerClass:
          "ag-left-aligned-header custom-header-padding left-aligned-header-padding",
        cellClass: "align-left",
        cellStyle: { textAlign: "left", padding: "0 0 0 10px" },
        width: 130,
        sort: null,
      },
      {
        headerName: "Impl Vol",
        field: "implied_volatility",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
        minWidth: 90,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Delta",
        field: "delta",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
        minWidth: 80,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Gamma",
        field: "gamma",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
        minWidth: 100,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Theta",
        field: "theta",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellStyle: { justifyContent: "end", padding: "0 10px 0 0" },
        minWidth: 80,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Vega",
        field: "vega",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellClass: "align-right",
        cellStyle: { padding: "0 10px 0 0", justifyContent: "end" },
        minWidth: 80,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Charm",
        field: "charm",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellClass: "align-right",
        cellStyle: { padding: "0 10px 0 0", justifyContent: "end" },
        minWidth: 85,
        flex: 1,
        sort: null,
      },
      {
        headerName: "Color",
        field: "color",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellClass: "align-right",
        minWidth: 75,
        cellStyle: { padding: "0 10px 0 0", justifyContent: "end" },
        flex: 1,
        sort: null,
      },
      {
        headerName: "Rho",
        field: "rho",
        headerClass:
          "ag-right-aligned-header custom-header-padding right-aligned-header-padding",
        valueFormatter: valueFormatter,
        cellClass: "align-right",
        minWidth: 80,
        cellStyle: { padding: "0 10px 0 0", justifyContent: "end" },
        flex: 1,
        sort: null,
      },
    ],
    []
  );

  useEffect(() => {
    setIsFetching(true);
    const fetchDataCall = async () => {
      try {
        const rowData = await fetchData(
          API_ENDPOINTS.GET_INDEX_OPTION_GREEKS()
        );
        setRowData(rowData);
        setTimeout(() => setIsFetching(false), 750);
      } catch (error) {
        console.log("render error");
      }
    };
    fetchDataCall();
    const intervalId = setInterval(fetchDataCall, 120000);

    return () => {
      clearInterval(intervalId);
    };
  }, [count]);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };
  return (
    <div className="">
      <header
        className="flex items-center p-2 justify-between"
        style={{ backgroundColor: "#e5ebf2" }}
      >
        <span className="text-sm font-semibold font-sans">
          Greeks Analayser
        </span>
        <div className="pl-1">
          <TableRefresher
            cellClickedListener={cellClickedListener}
            showSpinner={isFetching}
          />
        </div>
      </header>
      <div style={gridStyle} className="ag-theme-alpine">
        {isFetching ? (
          <ShimmerTable row={6} col={6} />
        ) : (
          <AgGridReact
            localeText={{ noRowsToShow: "Loading..." }}
            columnDefs={columnOptions}
            defaultColDef={defaultColDef}
            rowData={rowData}
            getRowStyle={getRowStyle}
            ref={gridApiRef}
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns={true}
            // onFirstDataRendered={onFirstDataRendered}
            domLayout="normal"
          ></AgGridReact>
        )}
      </div>
    </div>
  );
}

export default GreeksGrid;
