/*eslint-disable*/
import { useMemo, useState, useEffect } from "react";
import { apiConfig } from "apiConfig";
import { useFetchData } from "../utils/customFetch";

async function fetchData1(fetchData) {
  try {
    //need to get production url
    const response = await fetchData(
      apiConfig.THOR_URL + "v1/getAllSourceSecurities"
    );
    return response;
  } catch (error) {
    console.log("Error in fetching getSecurities - ", error);
    return "Error";
  }
}

//done this for memonizing the getting data
// const data = fetchData1();

async function fetchData2(fetchData) {
  const data = await fetchData1(fetchData);
  return data;
}

function getNextFetchTime() {
  const now = new Date();
  const nextFetch = new Date();
  nextFetch.setHours(9, 10, 0, 0);
  //default today morning 9:10AM

  if (now > nextFetch) {
    nextFetch.setDate(nextFetch.getDate() + 1);
    //Fri May 17 2024 09:10:00 GMT+0530 (India Standard Time)
  }
  //If time is above 9:10AM the it is set to next days' 9:10AM
  return nextFetch.getTime();
}

export function useRealtimeZenSecurities2() {
  const [fetchTime, setFetchTime] = useState(getNextFetchTime());
  const { fetchData } = useFetchData();
  useEffect(() => {
    const now = new Date().getTime();
    const timeUntilNextFetch = fetchTime - now;

    const timeoutId = setTimeout(() => {
      setFetchTime(getNextFetchTime());
    }, timeUntilNextFetch);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchTime]);

  const store = useMemo(() => fetchData2(fetchData), [fetchTime]);
  return store;
}
