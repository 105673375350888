/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/phoenix-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Provider, useSelector } from "react-redux";
import store from "./app/store";
import styles from "./index.css";
import App from "App";
import "qtip2/dist/jquery.qtip.min.css";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "../src/config";

const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(
  origin
);
const onRedirectCallback = (appState) => {
  window.history.replaceState({}, document.title, window.location.pathname);

  if (appState && appState.returnTo) {
    window.location.pathname = appState.returnTo;
  }
};

ReactDOM.render(
  isInternalIP ? (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  ) : (
    <Auth0Provider
      domain={"phoenix-lab.us.auth0.com"}
      clientId={config.CLIENT_ID}
      authorizationParams={{
        redirect_uri: config.UI_URL + "default/dashboard",
        audience: "https://service_api",
        scope:
          "openid profile email offline_access read:current_user update:current_user_metadata read:roles",
        responseType: "token id_token",
      }}
      cacheLocation="localstorage"
      debug={true}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true} // Ensures session persistence
      useRefreshTokensFallback={true}
    >
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  ),
  document.getElementById("root")
);
