/*eslint-disable*/
import React, { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { useState, useEffect } from "react";
import { useGlobalState } from "components/globalVariable";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import classNames, { ShimmerTable } from "react-shimmer-effects";
import { IoMdSearch } from "react-icons/io";
import {
  setPositionTableOptionsData,
  setFilterText,
} from "../container/PositionTableOptionSlice";
import TableRefresher from "components/TableRefreshComponet/TableRefresher";
import PositionOrderForm from "../container/PositionOrderForm";
import { apiConfig } from "apiConfig";
import { FaSearch } from "react-icons/fa";
import { useFetchData } from "utils/customFetch";
import API_ENDPOINTS from "services/api";

let strategyLists;
const PositionsGrid = () => {
  const { fetchData } = useFetchData();
  const [count, setCount] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [refreshTime, setRefreshTime] = useState(
    new Date().toLocaleTimeString([], { hour12: true }).replace("pm", "PM")
  );
  const { broker, dates, security, portfolio, entity, strategy, zenSecIds } =
    useSelector((state) => state.filter);
  const { columnOptions } = useSelector((state) => state.positionTableOption);
  const {
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
    selectedAccount,
  } = useSelector((state) => state.clientSelection);
  const dispatch = useDispatch();
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const [clientName] = useGlobalState("clientName");
  const [roles] = useGlobalState("roles");
  const isTrader = roles.includes("TRADER");

  strategyLists = useSelector((state) => state.filter.strategyList);
  const [rowData, setRowData] = useState();
  const filterText = useSelector(
    (state) => state.positionTableOption.filterText
  );
  const gridRef = useRef(null);

  const onGridReady = (params) => {
    gridRef.current = params.api; // Store the API reference
    calculateSum();
  };
  const onFirstDataRendered = (params) => {
    // Auto-size all columns based on content
    params.columnApi.autoSizeAllColumns({
      autoSizeStrategy: { type: "fitCellContents" },
    });

    // Ensure minWidth is enforced
    params.columnApi.getAllColumns().forEach((column) => {
      const colId = column.getColId();
      const colDef = column.getColDef();
      const minWidth = colDef.minWidth || 0;

      // Get the actual width after auto-sizing
      const actualWidth =
        params.columnApi.getColumnState().find((c) => c.colId === colId)
          ?.width || 0;

      // If auto-sized width is smaller than minWidth, set it to minWidth
      if (actualWidth < minWidth) {
        params.columnApi.setColumnWidth(colId, minWidth);
      }
    });
  };

  const getFilteredRows = () => {
    if (gridRef.current) {
      const filteredRows = [];
      gridRef.current.forEachNode((node) => {
        if (node.rowIndex !== null) {
          // Only include visible rows
          filteredRows.push(node.data);
        }
      });
      return filteredRows;
    }
    return [];
  };
  const dateConversion = (val) => {
    const dateObj = new Date(val);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [isSingleDate, setIsSingleDate] = useState(true);
  useEffect(() => {
    if (dateConversion(dates[0]) === dateConversion(dates[1])) {
      setIsSingleDate(true);
    } else {
      setIsSingleDate(false);
    }
  }, [dates]);

  const cellClickedListener = () => {
    setCount(count + 1);
  };

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    headerClass: { textAlign: "center" },
    cellStyle: (params) => {
      if (params?.column?.colId === "unrealizedPnl") {
        if (params.value < 0) {
          return { color: "red", display: "flex", justifyContent: "flex-end" };
        } else {
          return {
            display: "flex",
            justifyContent: "flex-end",
            color: "#00cc00",
          };
        }
      } else {
        return { display: "flex", justifyContent: "flex-end" };
      }
    },
  };

  const rowStyle = { background: "" };

  const getRowStyle = (params) => {
    if (params.value < 0) {
      return { color: "red" };
    }
  };

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setIsFetching(true);
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const body = {
      ...(dates[0].toLocaleDateString() !== new Date().toLocaleDateString()
        ? {
            startTimestamp: `${dateConversion(dates[0])} 00:00:00`,
            endTimestamp: `${dateConversion(dates[1])} 23:59:59`,
          }
        : {}),
      positionCompKeyFilter: {
        clientId: clientId,
        ...(zenSecIds === null || zenSecIds?.length === 0
          ? {}
          : { zenSecIds: zenSecIds }),

        ...(selectedAccount === "all" ||
        selectedAccount === "" ||
        selectedAccount === null ||
        selectedAccount === undefined
          ? {}
          : { accountIds: [selectedAccount] }),
        // Conditionally include strategyIds only if strategy is not empty
        ...(selectedStrategy === "all" ||
        selectedStrategy === "" ||
        selectedStrategy === null ||
        selectedStrategy === undefined
          ? {}
          : { strategyIds: [selectedStrategy] }),
        ...(selectedBroker === "all" ||
        selectedBroker === "" ||
        selectedBroker === null ||
        selectedBroker === undefined
          ? {}
          : { brokers: [selectedBrokerName] }), // Only include brokers if broker is not "ALL"
      },
    };

    const fetchBeta = async () => {
      try {
        const response = await fetchData(
          API_ENDPOINTS.GET_BETA(),
          "POST",
          header,
          body
        );
        return response;
      } catch (err) {
        console.log("Error in beta:", err);
        return [];
      }
    };

    const fetchPositions = async () => {
      try {
        const response = await fetchData(
          API_ENDPOINTS.GET_POSITION(),
          "POST",
          header,
          body
        );
        return response;
      } catch (err) {
        console.log("Error in fetching positions:", err);
        return [];
      }
    };

    const mergeBetaWithPositions = (positions, betaData, dates) => {
      if (dateConversion(dates[0]) === dateConversion(dates[1])) {
        // If start and end dates are the same, map only using zenSecId
        const betaMap = new Map(
          betaData.map((item) => [
            `${item.positionCompositeKey.zenSecId}`,
            item.beta,
          ])
        );

        return positions.map((position) => {
          const key = `${position.positionCompositeKey.zenSecId}`;

          return {
            ...position,
            beta: betaMap.get(key) || null,
          };
        });
      } else {
        // If start and end dates are different, map using both trade_date and zenSecId
        const betaMap = new Map(
          betaData.map((item) => [
            `${item.date}-${item.positionCompositeKey.zenSecId}`,
            item.beta,
          ])
        );

        return positions.map((position) => {
          const key = `${position.date}-${position.positionCompositeKey.zenSecId}`;

          return {
            ...position,
            beta: betaMap.get(key) || null,
          };
        });
      }
    };

    const fetchDataCall = async () => {
      try {
        const positionsData = await fetchPositions();
        const betaData = await fetchBeta();

        const mergedData = mergeBetaWithPositions(
          positionsData,
          betaData,
          dates
        );
        setRowData(mergedData);
        setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
        setTimeout(() => setIsFetching(false), 750);
      } catch (err) {
        console.log("Error in fetchData:", err);
      }
    };

    fetchDataCall();
    const intervalId = setInterval(fetchDataCall, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    zenSecIds,
    dates,
    count,
    clientName,
    strategy,
    broker,
    selectedAccount,
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
  ]);

  const containerStyle = useMemo(
    () => ({ height: "auto", width: "100%", overflow: "hidden" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "75vh", width: "93vw" }), []);
  const filterStyle = useMemo(() => ({ height: "50px", width: "100%" }), []);

  const [topRowData, setTopRowData] = useState({
    unrealizedPnl: 0,
    curMarketValue: 0,
    openCost: 0,
    averageCostPerShare: 0,
  });

  function calculateSum() {
    let latestDate = dateConversion(dates[1]);
    let latestData;

    if (filterText !== "") {
      latestData = getFilteredRows()?.filter((row) => row?.date === latestDate);
    } else {
      latestData = rowData?.filter((row) => row?.date === latestDate);
    }
    if (rowData?.length) {
      let unrealizedPnlSum = 0;
      let curMarketValueSum = 0;
      let openCostSum = 0;
      let averageCostPerShareSum = 0;
      latestData?.forEach((row) => {
        unrealizedPnlSum += Math.round(row?.unrealizedPnl);
        curMarketValueSum += Math.round(row?.curMarketValue);
        openCostSum += Math.round(row?.openCost);
        averageCostPerShareSum += Math.round(row?.averageCostPerShare);
      });
      // rowData.forEach(row => {
      //     realizedLTDPnlsum += Math.round(row?.realizedLTDPnl);
      // });
      setTopRowData({
        unrealizedPnl: unrealizedPnlSum,
        curMarketValue: curMarketValueSum,
        openCost: openCostSum,
        averageCostPerShare: averageCostPerShareSum,
      });
    } else {
      setTopRowData({
        unrealizedPnl: 0,
        curMarketValue: 0,
        openCost: 0,
        averageCostPerShare: 0,
      });
    }
  }
  useEffect(() => {
    calculateSum();
  }, [rowData, filterText]);

  const pinnedTopRowData = useMemo(() => {
    return [topRowData];
  }, [topRowData]);
  const optionsUpdater = (stateList) => {
    const newdata = columnOptions.map((option, i) => {
      return { ...option, sort: stateList[i] };
    });

    dispatch(setPositionTableOptionsData(newdata));
  };
  const handleSortingData = (e) => {
    const state = e.columnApi?.getColumnState();
    const sortState = state?.map((item) => item.sort);
    optionsUpdater(sortState);
  };

  return (
    <>
      {
        <div style={gridStyle} className="ag-theme-alpine">
          <header
            className="flex items-center p-2 justify-between"
            style={{ backgroundColor: "#e5ebf2" }}
          >
            <span className="text-sm font-semibold font-sans">Positions</span>
            <div className="flex items-center">
              <div className="relative flex items-center">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400 absolute left-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <input
                  type="search"
                  className="bg-slate-50 font-semibold rounded-md pl-8 pr-2 py-2 border text-gray-500 focus:ring-2"
                  placeholder="Search"
                  value={filterText}
                  onChange={(e) => dispatch(setFilterText(e.target.value))}
                />
              </div>
              <div className="pl-1">
                <TableRefresher
                  cellClickedListener={cellClickedListener}
                  showSpinner={isFetching}
                />
              </div>
            </div>

            {/* <div className="flex items-center justify-end">
                  <TableRefresher
                    className="flex-shrink-0"
                    cellClickedListener={cellClickedListener}
                    showSpinner={isFetching}
                  /> */}
            {/* </div> */}
          </header>
          {isFetching ? (
            <ShimmerTable row={7} col={5} />
          ) : (
            <>
              {/* <div className="absolute flex items-center justify-end  my-2 z-10 space-x-4 sm:right-2 lg:right-4 md:right-4 lg:min-w-[240px] md:min-w-[200px] sx:min-w-[150px]">
                <div className="relative flex items-center">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400 absolute left-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  <input
                    type="text"
                    className="bg-slate-50 font-semibold rounded-sm pl-8 pr-2 py-2 border text-gray-500 lg:max-w-[190px] md:max-w-[150px] sx:max-w-[100px]"
                    placeholder="Search"
                    value={filterText}
                    onChange={(e) => dispatch(setFilterText(e.target.value))}
                  />
                </div>
                <div className="flex items-center">
                  <TableRefresher
                    className="flex-shrink-0"
                    cellClickedListener={cellClickedListener}
                    showSpinner={isFetching}
                  />
                </div>
              </div> */}
              <AgGridReact
                localeText={{
                  noRowsToShow:
                    "Your portfolio is empty. Start trading to hold positions!",
                }}
                columnDefs={[
                  ...columnOptions,
                  ...(isTrader
                    ? [
                        {
                          headerName: "",
                          field: "tradingSymbol",
                          width: 150,
                          sort: null,
                          cellStyle: { padding: "0 20px 0 0" },
                          cellRenderer: PositionOrderForm,
                          cellRendererParams: {
                            tableData: rowData,
                          },
                        },
                      ]
                    : []),
                ]}
                defaultColDef={defaultColDef}
                filterStyle={filterStyle}
                onGridReady={onGridReady}
                //pagination
                //paginationPageSize={10}
                pinnedTopRowData={pinnedTopRowData}
                getRowStyle={getRowStyle}
                rowData={rowData}
                // onFirstDataRendered={onFirstDataRendered}
                onSortChanged={handleSortingData}
                domLayout="normal"
                suppressDragLeaveHidesColumns={true}
                quickFilterText={filterText}
              ></AgGridReact>
            </>
          )}
        </div>
      }
    </>
  );
};

export default PositionsGrid;
export { strategyLists };
