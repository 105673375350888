import Cookies from "universal-cookie";
import config from "../config";
import { apiConfig } from "../apiConfig";

const cookies = new Cookies();

const getClientId = () => {
  return cookies.get("clientId") || "";
};

const API_ENDPOINTS = {
  GET_PORTFOLIO_BETA: () =>
    `${apiConfig.THOR_URL}v1/${getClientId()}/trade/portfolioBeta`,
  GET_TRADES: () => `${apiConfig.THOR_URL}v1/${getClientId()}/trade/getTrades`,
  GET_BETA: () => `${apiConfig.THOR_URL}v1/${getClientId()}/trade/beta`,
  GET_POSITION: () => `${apiConfig.THOR_URL}v1/${getClientId()}/trade/position`,
  GET_PNL: () => `${apiConfig.THOR_URL}v1/${getClientId()}/trade/pnl`,
  GET_ZEN_ORDER_STATE: () =>
    `${apiConfig.THOR_URL}v1/${getClientId()}/order/getZenOrderState`,

  PLACE_ORDER: () =>
    `${apiConfig.THOR_URL}v1/${getClientId()}/order/placeOrder`,
  GET_LATEST_ORDER: () =>
    `${apiConfig.THOR_URL}v1/${getClientId()}/order/getLatestOrder`,

  GET_MARGIN: () => `${apiConfig.THOR_URL}v1/${getClientId()}/margins`,
  GET_INDEX_OPTION_GREEKS: () => `${apiConfig.THOR_URL}v1/indexOptionGreeks`,

  GET_EQUITY_EOD_PRICE_WITH_SYMBOL: () =>
    `${config.CORS_URL}getEquityEODPriceWithSymbol`,
  GET_INDEX_EOD_PRICE: () => `${config.CORS_URL}getIndexEODPrice`,

  GET_ZEN_SECURITY_ANALYSER: () => `${apiConfig.PROXY_URL}api/v1/db/connect`,
};

export default API_ENDPOINTS;
