/* eslint-disable */
import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { useState, useEffect } from "react";
import "./TradesGrid.css";
import { useGlobalState } from "../../globalVariable";
import { ShimmerTable } from "react-shimmer-effects";
import { useSelector, useDispatch } from "react-redux";
import { setTradestableOptionsData } from "../container/TradesTableOptionSlice";
import TableRefresher from "components/TableRefreshComponet/TableRefresher";
import { apiConfig } from "apiConfig";
import { useFetchData } from "utils/customFetch";
import API_ENDPOINTS from "services/api";

let strategyLists;
const TradesGrid = ({ other }) => {
  const { fetchData } = useFetchData();
  const [count, setCount] = useState(1);
  const [refreshTime, setRefreshTime] = useState(
    new Date().toLocaleTimeString([], { hour12: true }).replace("pm", "PM")
  );
  const {
    dates,
    broker,
    security,
    exchangeFilter,
    portfolio,
    entity,
    strategy,
    zenSecIds,
  } = useSelector((state) => state.filter);
  const {
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
    selectedAccount,
  } = useSelector((state) => state.clientSelection);
  const { columnOptions } = useSelector((state) => state.tradesTableOption);
  const strategyList = useSelector((state) => state.filter.strategyList);
  strategyLists = strategyList;
  const dispatch = useDispatch();
  const [clientId] = useGlobalState("clientId");
  const [accountIds] = useGlobalState("accountIds");
  const [clientName] = useGlobalState("clientName");

  const dateConversion = (val) => {
    const dateObj = new Date(val);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [rowData, setRowData] = useState();

  const cellClickedListener = () => {
    setCount(count + 1);
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      editable: false,
      filter: true,
      cellStyle: { textAlign: "center" },
    }),
    []
  );
  const rowStyle = { background: "" };

  const getRowStyle = (params) => {
    if (params.value < 0) {
      return { color: "#87CEEB" };
    }
  };

  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    const fetchTrades = async () => {
      setIsFetching(true);
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const body = {
        startTimestamp: `${dateConversion(dates[0])} 00:00:00`,
        endTimestamp: `${dateConversion(dates[1])} 23:59:59`,
        positionCompKeyFilter: {
          clientId: clientId,
          ...(zenSecIds === null || zenSecIds?.length === 0
            ? {}
            : { zenSecIds: zenSecIds }),
          ...(selectedAccount === "all" ||
          selectedAccount === "" ||
          selectedAccount === null ||
          selectedAccount === undefined
            ? {}
            : { accountIds: [selectedAccount] }),
          // Conditionally include strategyIds only if strategy is not empty
          ...(selectedStrategy === "all" ||
          selectedStrategy === "" ||
          selectedStrategy === null ||
          selectedStrategy === undefined
            ? {}
            : { strategyIds: [selectedStrategy] }),
          ...(selectedBroker === "all" ||
          selectedBroker === "" ||
          selectedBroker === null ||
          selectedBroker === undefined
            ? {}
            : { brokers: [selectedBrokerName] }), // Only include brokers if broker is not "ALL"
        },
      };
      try {
        const json = await fetchData(
          API_ENDPOINTS.GET_TRADES(),
          "POST",
          header,
          body
        );
        setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
        setRowData(Array.from(json));
        setTimeout(() => setIsFetching(false), 750);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchTrades();
  }, [
    zenSecIds,
    dates,
    count,
    clientId,
    selectedStrategy,
    selectedBroker,
    exchangeFilter,
    selectedAccount,
  ]);

  const containerStyle = useMemo(
    () => ({ height: "auto", width: "100%", overflow: "hidden" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "75vh", width: "93vw" }), []);
  const filterStyle = useMemo(() => ({ height: "50px", width: "100%" }), []);
  const tableStyle = useMemo(
    () => ({
      height: "100%",
      width: "100%",
      margin: "15px",
      position: "relative",
      border: "2px solid black",
    }),
    []
  );
  const tdStyle = useMemo(() => ({ width: "50%" }), []);
  const halfStyle = useMemo(
    () => ({ width: "500px", "padding-left": "15px" }),
    []
  );

  const [open, setOpen] = React.useState(false);
  const optionsUpdater = (stateList) => {
    const newdata = columnOptions.map((option, i) => {
      return { ...option, sort: stateList[i] };
    });

    dispatch(setTradestableOptionsData(newdata));
  };
  const handleSortingData = (e) => {
    const state = e.columnApi?.getColumnState();
    const sortState = state?.map((item) => item.sort);
    optionsUpdater(sortState);
  };

  const onFirstDataRendered = (params) => {
    // Auto-size all columns based on content
    params.columnApi.autoSizeAllColumns({
      autoSizeStrategy: { type: "fitGridWidth" }, // Ensures columns fill the grid
    });

    // Ensure minWidth is enforced
    params.columnApi.getAllColumns().forEach((column) => {
      const colId = column.getColId();
      const colDef = column.getColDef();
      const minWidth = colDef.minWidth || 0;

      // Get the actual width after auto-sizing
      const actualWidth =
        params.columnApi.getColumnState().find((c) => c.colId === colId)
          ?.width || 0;

      // If auto-sized width is smaller than minWidth, set it to minWidth
      if (actualWidth < minWidth) {
        params.columnApi.setColumnWidth(colId, minWidth);
      }
    });
  };

  return (
    <>
      <div style={gridStyle} className="ag-theme-alpine">
        <header
          className="flex items-center p-2 justify-between"
          style={{ backgroundColor: "#e5ebf2" }}
        >
          <span className="text-sm font-semibold font-sans">Trades</span>

          <div className="pl-1">
            <TableRefresher
              cellClickedListener={cellClickedListener}
              showSpinner={isFetching}
            />
          </div>
        </header>
        {isFetching ? (
          <ShimmerTable row={5} col={5} />
        ) : (
          <>
            <AgGridReact
              localeText={{
                noRowsToShow: "You have no trades yet... Start trading now!",
              }}
              columnDefs={columnOptions}
              defaultColDef={defaultColDef}
              rowData={rowData}
              filterStyle={filterStyle}
              onSortChanged={handleSortingData}
              suppressDragLeaveHidesColumns={true}
              // onFirstDataRendered={onFirstDataRendered}
              domLayout="normal"
            ></AgGridReact>
          </>
        )}
      </div>
    </>
  );
};

export default TradesGrid;
export { strategyLists };
