/* eslint-disable */
import { setPnlType } from "components/PnlLineChart/PnlLineChartSlice";

import { useDispatch, useSelector } from "react-redux";
import API_ENDPOINTS from "services/api";
import { useEffect } from "react";
import { useGlobalState } from "components/globalVariable";
import { setLineChartData } from "components/PnlLineChart/PnlLineChartSlice";
import { useFetchData } from "utils/customFetch";
import { useRef } from "react";
import PnlChartIndividualTemplate from "./PnlChartIndividualTemplate";
const PnlChartIndividual = () => {
  const pnlChartData = useSelector((state) => state.pnlChart.pnlChartData);
  const lineChartData = useSelector(
    (state) => state.pnlLineChart.lineChartData
  );
  const dispatch = useDispatch();
  const type = useSelector((state) => state.pnlLineChart.type);
  const pnlType = useSelector((state) => state.pnlLineChart.pnlType);
  const isBtnClick = useSelector((state) => state.pnlLineChart.isBtnClick);
  const chartRef = useRef(null);
  const formattedData = lineChartData?.map((item) => {
    return {
      unrealizedDTDPnl: item?.unRealizedDTDPnl,
      unrealizedWTDPnl: item?.unRealizedWTDPnl,
      unrealizedMTDPnl: item?.unRealizedMTDPnl,
      unrealizedYTDPnl: item?.unRealizedYTDPnl,
      unrealizedLTDPnl: item?.unRealizedLTDPnl,
      realizedDTDPnl: item?.realizedDTDPnl,
      realizedWTDPnl: item?.realizedWTDPnl,
      realizedMTDPnl: item?.realizedMTDPnl,
      realizedYTDPnl: item?.realizedYTDPnl,
      realizedLTDPnl: item?.realizedLTDPnl,
      date: item?.date,
    };
  });
  const options = {
    chart: {
      type: "line",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Date",
      },
    },
    yAxis: {
      title: {
        text: "PNL",
      },
    },
    series: [
      {
        name: `${pnlChartData?.tradingSymbol} ${type} PNL`,
        data: formattedData?.map((item) => [
          new Date(item.date).getTime(), // Converts date to timestamp
          item[`${pnlType}${type}Pnl`],
        ]),
        color: "#8884D8",
      },
    ],
  };

  const CustomLegend = () => {
    return (
      <ul className="flex space-x-4">
        <li className="flex items-center">
          <span
            className="block w-10 h-1"
            style={{ backgroundColor: "#8884D8" }}
          />
          <span className="ml-2">
            {pnlChartData?.tradingSymbol} {type} PNL
          </span>{" "}
          {/* Custom legend text */}
        </li>
      </ul>
    );
  };
  const { fetchData } = useFetchData();
  const dateConversion = (val) => {
    if (!val) {
      console.error("Invalid date input:", val);
      return "Invalid Date";
    }

    // Ensure `val` is a Date object before formatting
    const dateObj = val instanceof Date ? val : new Date(val);
    if (isNaN(dateObj.getTime())) {
      console.error("Invalid date format:", val);
      return "Invalid Date";
    }

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const {
    selectedStrategy,
    selectedBroker,
    selectedBrokerName,
    selectedAccount,
  } = useSelector((state) => state.clientSelection);
  const [clientId] = useGlobalState("clientId");
  const handlePnlType = () => {
    if (pnlType === "realized") {
      dispatch(setPnlType("unrealized"));
    } else {
      dispatch(setPnlType("realized"));
    }
  };
  useEffect(() => {
    const fetchDataCall = async () => {
      try {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const body = {
          //  15 (days) * 24 (hours) * 60 (minutes) * 60 (seconds) * 1000 (milliseconds )
          startTimestamp: `${dateConversion(new Date(2024, 6, 1))} 00:00:00`,
          endTimestamp: `${dateConversion(new Date())} 23:59:59`,
          positionCompKeyFilter: {
            clientId: clientId,
            ...(selectedStrategy === "all" ||
            selectedStrategy === "" ||
            selectedStrategy == null
              ? {}
              : { strategyIds: [selectedStrategy] }),
            ...(selectedBroker === "all" ||
            selectedBroker === "" ||
            selectedBroker == null
              ? {}
              : { brokers: [selectedBrokerName] }), // Only include brokers if broker is not "ALL"
            ...(selectedAccount === "all" ||
            selectedAccount === "" ||
            selectedAccount === null ||
            selectedAccount === undefined
              ? {}
              : { accountIds: [selectedAccount] }),
            zenSecIds: [pnlChartData?.positionCompositeKey?.zenSecId],
          },
        };
        const rowData = await fetchData(
          API_ENDPOINTS.GET_PNL(),
          "POST",
          header,
          body
        );

        dispatch(setLineChartData(Array.from(rowData)));
      } catch (error) {
        console.log("render error", error);
      }
    };
    if (type !== "") fetchDataCall();
  }, []);

  useEffect(() => {
    const chart = chartRef.current?.chart;
    const endDate = new Date();
    if (chart) {
      chart.xAxis[0].setExtremes(
        Date.UTC(2024, 9, 1),
        Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
      );
    }
  }, []);
  return (
    <PnlChartIndividualTemplate
      pnlChartData={pnlChartData}
      type={type}
      pnlType={pnlType}
      dispatch={dispatch}
      options={options}
      handlePnlType={handlePnlType}
      chartRef={chartRef}
      CustomLegend={CustomLegend}
      isBtnClick={isBtnClick}
    />
  );
};

export default PnlChartIndividual;
