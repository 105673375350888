/* eslint-disable */
import React, { Children, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import logo from "./phoenix_logo.svg";
import zenLogo from "./logo.png";
import Zlogo from "./Zlogo.png";
// javascript plugin used to create scrollbars on windows
import "./sidebar.css";
import { BiSolidArrowToLeft } from "react-icons/bi";
import { ArrowLeftIcon, SearchIcon } from "lucide-react";
import { Tooltip } from "@mui/material";

var ps;

function Sidebar3(props) {
  const location = useLocation();
  const [open, setopen] = useState(false);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const activeRouteCheck = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  return (
    <div className="flex  z-[100] ">
      <div
        className={`bg-slate-800 h-full  p-4 pt-2 ${
          open ? "w-72" : "w-24 "
        } duration-500  fixed top-0 left-0`}
      >
        <ArrowLeftIcon
          className={`hidden md:block bg-white text-dark rounded-full absolute -right-2
         border-[1px] border-black cursor-pointer ${
           !open && "rotate-180"
         } duration-300`}
          size={20}
          onClick={() => setopen(!open)}
        />

        <div className="inline-flex items-start ">
          <img
            src={Zlogo}
            className={`h-14 rounded
            cursor-pointer -mt-6  mr-3 duration-500 ${
              open && "rotate-[360deg] "
            } ${!open && "-ml-2"}`}
          />
          <h1
            className={` text-white origin-left font-medium text-2xl duration-300 -mt-4 ${
              !open && "scale-0"
            }`}
          >
            Phoenix
          </h1>
        </div>

        {/*<div className={`flex items-center rounded-md bg-white/20 mt-6 
        ${!open ? "px-1.5" : "px-4 "} py-2`}>
            <SearchIcon className={`text-lg block float-left cursor-pointer
            ${open && "mr-2"}`} />
            <input type="search" placeholder="Search"
            className={
            `text-base bg-transparent w-full text-white focus:outline-none ${!open && "hidden"}`
            }
            />
        </div>*/}

        <ul className="pt-2 hidden md:block lg:block">
          {props.routes.map((prop, key) => (
            <li key={key} className="mb-3">
              <Tooltip title={!open && prop.name} placement="bottom">
                <Link
                  to={prop.layout + prop.path}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div
                    className={`inline-flex items-center gap-x-5 px-2 py-2
                        ${
                          activeRouteCheck(prop.path)
                            ? "bg-white/30"
                            : "hover:bg-white/30"
                        } w-full rounded-md `}
                  >
                    <span
                      className={` text-xl rounded cursor-pointer  duration-500 ${
                        !open && " ml-1 h-6"
                      }`}
                    >
                      {prop.icon}
                    </span>
                    <span
                      className={`text-white origin-left font-medium text-base duration-300 ${
                        !open ? "hidden" : "block"
                      }`}
                    >
                      {prop.name}
                    </span>
                  </div>
                </Link>
              </Tooltip>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar3;
