import React, { useRef, useState, useEffect, useCallback } from "react";
/// throttle.ts
export const throttle = (f) => {
  let token = null,
    lastArgs = null;

  const invoke = () => {
    f(...lastArgs);
    token = null;
  };

  const result = (...args) => {
    lastArgs = args;

    //If no token is present means, no function is waitng to execute in next animation frame
    if (!token) {
      token = requestAnimationFrame(invoke);
    }
  };
  result.cancel = () => token && cancelAnimationFrame(token);
  return result;
};

/// use-draggable.ts
// const id = (x) => x;

const useDraggable = ({
  onDrag = (x) => x,
  initialPosition = { x: 0, y: 0 },
} = {}) => {
  const [pressed, setPressed] = useState(false);
  const position = useRef(initialPosition);
  const ref = useRef();
  const unsubscribe = useRef();
  const startPosition = useRef({ x: 0, y: 0 }); // Store initial touch position
  const offset = useRef({ x: 0, y: 0 }); // Store drag offset

  const legacyRef = useCallback((elem) => {
    ref.current = elem;
    if (unsubscribe.current) {
      unsubscribe.current();
    }
    if (!elem) {
      return;
    }

    const handleStart = (e) => {
      // Prevent dragging if interacting with form elements
      const isFormElement = [
        "INPUT",
        "TEXTAREA",
        "SELECT",
        "BUTTON",
        "LABEL",
      ].includes(e.target.tagName);
      if (isFormElement || e.target.closest("button")) return;

      setPressed(true);
      e.preventDefault(); // Prevent scrolling on mobile while dragging

      // Capture the starting position
      const touch = e.touches ? e.touches[0] : e;
      startPosition.current = { x: touch.clientX, y: touch.clientY };

      // Calculate offset relative to the component's position
      const rect = elem.getBoundingClientRect();
      offset.current = {
        x: startPosition.current.x - rect.left,
        y: startPosition.current.y - rect.top,
      };
    };

    elem.addEventListener("mousedown", handleStart);
    elem.addEventListener("touchstart", handleStart, { passive: false });

    unsubscribe.current = () => {
      elem.removeEventListener("mousedown", handleStart);
      elem.removeEventListener("touchstart", handleStart);
    };
  }, []);

  useEffect(() => {
    if (!pressed) {
      return;
    }

    const handleMove = throttle((event) => {
      if (!ref.current || !position.current) {
        return;
      }

      // const pos = position.current;
      const elem = ref.current;

      let clientX = 0;
      let clientY = 0;

      if (event.type === "mousemove") {
        clientX = event.clientX;
        clientY = event.clientY;
      } else if (event.type === "touchmove") {
        if (event.touches.length > 0) {
          clientX = event.touches[0].clientX;
          clientY = event.touches[0].clientY;
        }
      }

      // Move relative to original position without jumping
      position.current = onDrag({
        x: clientX - offset.current.x,
        y: clientY - offset.current.y,
      });

      elem.style.transform = `translate(${position.current.x}px, ${position.current.y}px)`;
    });

    const handleEnd = () => {
      setPressed(false);
    };

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handleEnd);
    document.addEventListener("touchmove", handleMove, { passive: false });
    document.addEventListener("touchend", handleEnd);

    return () => {
      handleMove.cancel();
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };
  }, [pressed, onDrag]);

  return [legacyRef, pressed];
};

export const DraggableComponent = ({ children }) => {
  const handleDrag = useCallback(
    ({ x, y }) => ({
      x: Math.max(0, x),
      y: Math.max(0, y),
    }),
    []
  );
  // Calculate initial center position
  const initialPosition = {
    x:
      window.innerWidth <= 480
        ? 0
        : window.innerWidth <= 768
        ? 100
        : window.innerWidth / 2.5,
    y: window.innerWidth <= 480 ? 100 : window.innerHeight / 4.5,
  };

  const [ref, pressed] = useDraggable({
    onDrag: handleDrag,
    initialPosition,
  });

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        transform: `translate(${initialPosition.x}px, ${initialPosition.y}px)`,
        cursor: pressed ? "grabbing" : "grab",
      }}
    >
      {children}
    </div>
  );
};
