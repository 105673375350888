/*eslint-disable*/
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../routes.js";
import { FiFilter } from "react-icons/fi";
import {
  fetchClientInfo,
  fetchClients,
  setSelectedClient,
  setSelectedBroker,
  setSelectedAccount,
  setSelectedStrategy,
} from "reducers/clientSelectionSlice";
import { setGlobalState } from "components/globalVariable";
import { useGlobalState } from "components/globalVariable";
import Cookies from "universal-cookie";
import BetaContainer from "components/Beta/BetaContainer";
import MarginTemplate from "components/Margin/container/MarginTemplate.js";
import { saveStrategyList } from "reducers/FilterSlice.js";
import { setBrokers } from "reducers/BrokerSlice.js";
import { setClientId } from "components/ExternalTrades/ExternalTradesSlice.js";
import { toggleDropdown, toggleFilterDropdown } from "./DemoNavbarSlice.js";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchRoles } from "reducers/clientSelectionSlice.js";
import { Link } from "react-router-dom";

export const DemoNavbar = () => {
  const dispatch = useDispatch();
  const { user, logout } = useAuth0();
  const {
    clients,
    selectedClient,
    clientInfo,
    selectedBroker,
    selectedAccount,
    selectedStrategy,
    rolesList,
    status,
    error,
  } = useSelector((state) => state.clientSelection);

  const [isOpen, setIsOpen] = useState(false);
  const isDropdown = useSelector((state) => state.navbar.isDropdown);
  const isFilterDropdown = useSelector(
    (state) => state.navbar.isFilterDropdown
  );
  const [clientId] = useGlobalState("clientId");
  const cookies = new Cookies();
  const profileRef = useRef(null);
  const profileSmRef = useRef(null); //ref for small screen profile
  const [userRoles] = useGlobalState("roles");

  const filteredRoutes = routes.filter((route) => {
    if (route.roles.length == 0) return true;
    return route.roles.some((role) => userRoles?.includes(role));
  });

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin + "/home" }, // Redirect to the homepage after logout
    });
  };
  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchClients());
  }, [dispatch]);

  //loading from the cookies
  useEffect(() => {
    if (clientId) {
      dispatch(fetchClientInfo(clientId));
      dispatch(setSelectedClient(clientId));
    }
  }, [dispatch]);

  function extractAccountIds(response) {
    return response?.broker_infos?.flatMap((broker) =>
      broker.account_infos?.map((account) => account?.account_id)
    );
  }

  //updating the client and cookies
  useEffect(() => {
    if (selectedClient) {
      dispatch(fetchClientInfo(selectedClient));

      let clientName = clients?.find(
        (client) => client.client_id === selectedClient
      )?.client_name;

      setGlobalState("clientId", selectedClient);
      setGlobalState("clientName", clientName);
      cookies.set("clientId", selectedClient);
      cookies.set("clientName", clientName);
    }
  }, [selectedClient, clients, dispatch]);

  useEffect(() => {
    if (selectedClient && clients.length > 0) {
      const roles = clients.find(
        (client) => client.client_id === selectedClient
      )?.permissions;
      // const matchingRoles =
      //   rolesList?.filter((role) => roleIds.includes(role.role_id)) || [];
      // const roleName = matchingRoles?.map((role) => role?.role_name);
      cookies.set("roles", roles);
      setGlobalState("roles", roles);
    }
  }, [selectedClient, clients]);
  useEffect(() => {
    if (clientInfo) {
      let accountIds = extractAccountIds(clientInfo);
      cookies.set("accountIds", accountIds);
      setGlobalState("accountIds", accountIds);
    }
  }, [selectedClient, clients, clientInfo]);

  const availableBrokers = useMemo(
    () => clientInfo?.broker_infos || [],
    [clientInfo]
  );
  const selectedBrokerInfo = useMemo(() => {
    return availableBrokers.find(
      (broker) => broker.broker_id === selectedBroker
    );
  }, [availableBrokers, selectedBroker]);

  const availableAccounts = useMemo(() => {
    if (selectedBrokerInfo) {
      return selectedBrokerInfo.account_infos || [];
    }
    return availableBrokers.flatMap((broker) => broker.account_infos || []);
  }, [availableBrokers, selectedBrokerInfo]);

  const availableStrategies = useMemo(() => {
    if (selectedAccount === "all") {
      const allStrategies = availableAccounts.flatMap(
        (account) => account.strategies || []
      );
      return Array.from(new Set(allStrategies.map((s) => s.strategy_id))).map(
        (id) => allStrategies.find((s) => s.strategy_id === id)
      );
    }
    const selectedAccountInfo = availableAccounts.find(
      (account) => account.account_id === selectedAccount
    );
    return selectedAccountInfo ? selectedAccountInfo.strategies || [] : [];
  }, [availableAccounts, selectedAccount]);

  useEffect(() => {
    if (availableBrokers.length === 1) {
      dispatch(setSelectedBroker(availableBrokers[0].broker_id));
    } else {
      dispatch(setSelectedBroker("all"));
    }
  }, [availableBrokers, dispatch]);

  useEffect(() => {
    if (availableAccounts.length === 1) {
      dispatch(setSelectedAccount(availableAccounts[0].account_id));
    } else if (availableAccounts.length > 1 && !selectedAccount) {
      dispatch(setSelectedAccount("all"));
    }
  }, [availableAccounts, selectedAccount, dispatch]);

  useEffect(() => {
    if (availableStrategies.length === 1) {
      dispatch(setSelectedStrategy(availableStrategies[0].strategy_id));
    } else if (availableStrategies.length > 1 && !selectedStrategy) {
      dispatch(setSelectedStrategy("all"));
    }
  }, [availableStrategies, selectedStrategy, dispatch]);

  const handleClientChange = (e) => {
    dispatch(setSelectedClient(Number(e.target.value)));
    // const roleIds = clients?.find(client => client.client_id === selectedClient)?.role_ids;
    // const matchingRoles = rolesList?.filter(role => roleIds?.includes(role.role_id)) || [];
    // const roleName = matchingRoles?.map(role => role?.role_name)
    // console.log("Role Name : ", roleName);
    // dispatch(setRoles(roleName));

    // console.log("Clients : ", clients)
    // console.log("Getting roleid : ", clients.find(client => client.id == e.target.value)?.role_ids);
    // const role_id = clients.find(client => client.id === e.target.value)?.role_ids;
    // dispatch(setRoles(rolesList.find(role => role.role_id == role_id).role_name));
  };

  const handleBrokerChange = (e) => {
    dispatch(
      setSelectedBroker(
        e.target.value === "all" ? "all" : Number(e.target.value)
      )
    );
  };

  const handleAccountChange = (e) => {
    dispatch(
      setSelectedAccount(
        e.target.value === "all" ? "all" : Number(e.target.value)
      )
    );
  };

  const handleStrategyChange = (e) => {
    dispatch(
      setSelectedStrategy(
        e.target.value === "all" ? "all" : Number(e.target.value)
      )
    );
  };

  //update the availabe statergy list so the other tables will use it to map and show the statergy name
  useEffect(() => {
    if (availableStrategies) {
      dispatch(saveStrategyList(availableStrategies));
    }
  }, [
    selectedBrokerInfo,
    selectedBroker,
    clientInfo,
    selectedAccount,
    clientId,
  ]);

  useEffect(() => {
    if (availableBrokers) {
      dispatch(setBrokers(availableBrokers));
    }
  }, [selectedBrokerInfo, selectedBroker, clientInfo]);

  const LableButton = ({ title }) => {
    return (
      <div
        className="flex-shrink-0 z-[1] inline-flex items-center py-1 px-1 text-xs font-medium text-center text-gray-500 bg-gray-100 border-[1px] border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
        type="button"
      >
        {title}
      </div>
    );
  };

  const selectTagStyleText = `cursor-pointer bg-gray-50 border-[1px] border-gray-300 text-gray-900 text-xs rounded-e-lg border-s-gray-100 
  dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block p-1.5 dark:bg-gray-700 dark:border-gray-600 
  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 `;

  const getRouteTitle = () => {
    let brandName = "Orders";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };

  /*
  Show a blue line when loading
  Show a red line when there's an error
  Show a yellow line when there are no accounts allocated
  Not show anything when in a normal state
  */
  const LineLoader = ({ status, error, noAccounts }) => {
    let bgColor = "bg-blue-500"; // Default loading color
    if (error) bgColor = "bg-red-500";
    else if (noAccounts) bgColor = "bg-yellow-500";

    return (
      <div className="relative w-full h-1">
        {(status === "loading" || error || noAccounts) && (
          <div
            className={`absolute top-0 left-0 h-full ${bgColor} animate-[loader_2s_ease-in-out_infinite]`}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    const handleClickAnywhere = (event) => {
      if (profileRef.current && !profileRef?.current?.contains(event.target)) {
        dispatch(toggleDropdown(false));
      } else if (
        profileSmRef.current &&
        !profileSmRef?.current?.contains(event.target)
      ) {
        dispatch(toggleFilterDropdown(false));
      }
    };

    document.addEventListener("click", handleClickAnywhere);

    return () => {
      document.removeEventListener("click", handleClickAnywhere);
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 w-full z-50 dark bg-gray-800 pl-24">
      <div className="mx-2 px-2 sm:px-4 lg:px-4">
        <div className="relative flex items-center justify-between h-14">
          <div className="absolute inset-y-0 left-0 items-center hidden max-[1240px]:flex  flex-shrink-0">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="flex-grow max-w-4xl flex  items-center justify-start sm:items-stretch sm:justify-start">
            {/* <div className="hidden max-[1240px]:flex flex-shrink-0  absolute inset-y-0 left-0 items-center  ">
              <span className="text-white font-bold text-lg">{getRouteTitle()}</span>
            </div> */}

            {rolesList.length > 0 && (
              <>
                <div className="flex-shrink-0 flex flex-wrap justify-between items-center">
                  <div className="flex space-x-2 ml-10">
                    <div className="bg-gray-200 px-1.5 py-0 rounded-md">
                      <span className="text-xs font-medium text-gray-700">
                        Beta: <BetaContainer />
                      </span>
                    </div>

                    {selectedAccount && selectedAccount !== "all" ? (
                      <div className="bg-gray-200 px-1.5 py-0 rounded-md flex items-center">
                        <span className="text-xs font-medium text-gray-700 flex space-x-1 items-center">
                          Margin: <MarginTemplate />
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="hidden  min-[1240px]:flex  lg:ml-6">
                  <div className="flex-shrink-0 flex space-x-4 py-4 items-center">
                    {clients.length > 0 && rolesList.length > 0 && (
                      <div class="flex">
                        <LableButton title={"Client"} />
                        <select
                          className={
                            selectTagStyleText + " dark:focus:border-blue-500"
                          }
                          onChange={handleClientChange}
                          value={selectedClient || ""}
                        >
                          <option disabled value="">
                            Select Client
                          </option>
                          {!clients ? (
                            <p>No clientd</p>
                          ) : (
                            clients?.map((client) => (
                              <option
                                key={client.client_id}
                                value={client.client_id}
                              >
                                {client.client_name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    )}
                    {availableBrokers.length > 0 && (
                      <div class="flex">
                        <LableButton title={"Broker"} />
                        <select
                          className={
                            selectTagStyleText + " dark:focus:border-green-500"
                          }
                          onChange={handleBrokerChange}
                          value={selectedBroker || ""}
                        >
                          <option value="" disabled>
                            Select Broker
                          </option>
                          {availableBrokers.length > 1 && (
                            <option value="all">All Broker</option>
                          )}
                          {availableBrokers.map((broker) => (
                            <option
                              key={broker.broker_id}
                              value={broker.broker_id}
                            >
                              {broker.broker_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {availableAccounts.length > 0 && (
                      <div class="flex">
                        <LableButton title={"Account"} />
                        <select
                          className={
                            selectTagStyleText + " dark:focus:border-yellow-500"
                          }
                          onChange={handleAccountChange}
                          value={selectedAccount || ""}
                        >
                          <option value="" disabled>
                            Select Account
                          </option>
                          {availableAccounts.length > 1 && (
                            <option value="all">All Accounts</option>
                          )}
                          {availableAccounts.map((account) => (
                            <option
                              key={account.account_id}
                              value={account.account_id}
                              disabled={
                                selectedBroker === null ||
                                selectedBroker === "all"
                              }
                            >
                              {account.account_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {availableStrategies.length > 0 && (
                      <div class="flex">
                        <LableButton title={"Strategy"} />
                        <select
                          className={
                            selectTagStyleText + " dark:focus:border-red-500"
                          }
                          onChange={handleStrategyChange}
                          value={selectedStrategy || ""}
                        >
                          <option value="" disabled>
                            Select Strategy
                          </option>
                          {availableStrategies.length > 1 && (
                            <option value="all">All Strategies</option>
                          )}
                          {availableStrategies.map((strategy) => (
                            <option
                              key={strategy.strategy_id}
                              value={strategy.strategy_id}
                              disabled={
                                selectedBroker === null ||
                                selectedBroker === "all"
                              }
                            >
                              {strategy.strategy_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex-shrink-0 hidden min-[1240px]:flex  items-end left- ">
            <img
              src={user?.picture}
              alt="Profile"
              className="w-22 h-10 rounded-full cursor-pointer "
              onClick={(e) => {
                e.stopPropagation();
                dispatch(toggleDropdown(!isDropdown));
              }}
            />

            {isDropdown && (
              <div
                ref={profileRef}
                className="absolute top-full right-0 mt-2 w-96 bg-white rounded-md shadow-lg z-10 flex flex-col items-center p-4"
              >
                <img
                  src={user?.picture}
                  alt="Profile"
                  className="w-16 h-16 rounded-full mb-4"
                />
                <p className="text-lg font-semibold text-gray-800 mb-1">
                  {user?.nickname}
                </p>
                <p className="text-sm text-gray-600 mb-4">{user?.email}</p>
                <button
                  className="block px-6 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 transition"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`${isOpen ? "block" : "hidden"} min-[1240px]:hidden`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <select
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium w-full"
            onChange={handleClientChange}
            value={selectedClient || ""}
          >
            <option value="">Select Client</option>
            {!clients || !rolesList ? (
              <p>No clientd</p>
            ) : (
              clients?.map((client) => (
                <option key={client?.client_id} value={client?.client_id}>
                  {client?.client_name}
                </option>
              ))
            )}
          </select>
          {availableBrokers.length > 0 && (
            <select
              className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium w-full"
              onChange={handleBrokerChange}
              value={selectedBroker || ""}
            >
              <option value="">Select Broker</option>
              {availableBrokers.map((broker) => (
                <option key={broker.broker_id} value={broker.broker_id}>
                  {broker.broker_name}
                </option>
              ))}
            </select>
          )}
          {availableAccounts.length > 0 && (
            <select
              className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium w-full"
              onChange={handleAccountChange}
              value={selectedAccount || ""}
            >
              <option value="">Select Account</option>
              {availableAccounts.length > 1 && (
                <option value="all">All Accounts</option>
              )}
              {availableAccounts.map((account) => (
                <option key={account.account_id} value={account.account_id}>
                  {account.account_name}
                </option>
              ))}
            </select>
          )}
          {availableStrategies.length > 0 && (
            <select
              className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium w-full"
              onChange={handleStrategyChange}
              value={selectedStrategy || ""}
            >
              <option value="">Select Strategy</option>
              {availableStrategies.length > 1 && (
                <option value="all">All Strategies</option>
              )}
              {availableStrategies.map((strategy) => (
                <option key={strategy.strategy_id} value={strategy.strategy_id}>
                  {strategy.strategy_name}
                </option>
              ))}
            </select>
          )}

          {/* className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium w-full" */}

          {filteredRoutes?.map((route) => (
            <p className="bg-gray-900 text-white block md:hidden lg:hidden px-3 py-2 rounded-md text-base font-medium w-full ">
              <Link
                to={route.layout + route.path}
                style={{ textDecoration: "none", color: "white" }}
              >
                {route?.name}
              </Link>
            </p>
          ))}

          <p
            ref={profileSmRef}
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium w-full"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(toggleFilterDropdown(!isFilterDropdown));
            }}
          >
            Profile
          </p>

          {isFilterDropdown && (
            <div className="absolute top-full left-0 mt-2 w-96 bg-white rounded-md shadow-lg z-10 flex flex-col items-center p-4">
              <img
                src={user?.picture}
                alt="Profile"
                className="w-16 h-16 rounded-full cursor-pointer mb-4"
              />
              <p className="text-lg font-semibold text-gray-800 mb-1">
                {user?.nickname}
              </p>
              <p className="text-sm text-gray-600 mb-4">{user?.email}</p>
              <button
                className="block px-6 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 transition"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>

      {rolesList.length > 0 && (
        <LineLoader
          status={status}
          error={error}
          noAccounts={availableAccounts.length === 0 && clientInfo}
        />
      )}
    </nav>
  );
};
