import "./HomePage.css";
import logo from "./logo.png";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import config from "config";
const HomePage = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="home-container">
      <div className="logo-container mt-40">
        <img src={logo} alt="Phoenix" />
      </div>
      <div className="start-button-container mt-60 rounded-lg">
        <Button
          variant="primary"
          className="custom-button"
          onClick={() =>
            loginWithRedirect({
              redirectUri: `${config.UI_URL}default/dashboard`,
            })
          }
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
export default HomePage;
