import API_ENDPOINTS from "services/api";
const getAvailableMargin = async (
  fetchData,
  clientId,
  accountId,
  dispatch,
  setAvailableMargin
) => {
  try {
    const data = await fetchData(
      `${API_ENDPOINTS.GET_MARGIN()}?account_id=${accountId}`
    );
    dispatch(setAvailableMargin(data?.availableCash));
  } catch (error) {
    console.error("Error", error);
  }
};

export default getAvailableMargin;
